import {useHousehold} from '../data/use_household';
import {useLocation} from 'react-router-dom';
import {getHymnFromUrl} from '../shared';
import {getPageFromLocation} from './path';
import {Pages} from '../../common/pages';

export function useAllowInAppNotifications() {
  const {household} = useHousehold();
  const location = useLocation();
  return household && // no household => demo page
    !getHymnFromUrl(location.search) && // don't interrupt song playback
    getPageFromLocation(location) !== Pages.Onboarding // don't interrupt onboarding process
}
