import React, {useCallback} from 'react';
import {Frame} from './frame';
import {ButtonContent, Message, navigateToOnboardingPage, setOnboardingHouseholdSurveyResponse} from './common';
import {Button, LeftAlignedButton} from './button';
import styled from 'styled-components/macro';
import {ensureUnreachable} from '../../../common/util';
import {alert} from '../../util/confirm';
import {LocalStorageKey} from '../../data/client_local_storage';
import {HouseholdOriginSource} from '../../../common/model';
import {useLocalStorage} from '../../data/use_local_storage';
import {useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

interface Option {
  icon: React.ReactElement;
  prompt: string;
  source: HouseholdOriginSource;
}

export const SurveyDiscovery = () => {
  const {origin: selectedSource} = useLocalStorage(LocalStorageKey.OnboardingHouseholdSurveyResponses)[0];
  const {church} = useChurch();
  const navigate = useNavigate();

  const onContinue = useCallback(async () => {
    // TODO(hewitt): store selected source in database
    if (!selectedSource) {
      await alert({confirmation: <span>Please make a selection</span>});
      return;
    }
    switch (selectedSource) {
      case HouseholdOriginSource.Church:
      case HouseholdOriginSource.School:
        navigateToOnboardingPage(
          navigate,
          church ? OnboardingPages.SurveyHouseholdSchedule : OnboardingPages.ChurchChoice
        );
        break;
      case HouseholdOriginSource.Friend:
      case HouseholdOriginSource.Self:
        navigateToOnboardingPage(navigate, OnboardingPages.Awesome);
        break;
      default:
        ensureUnreachable(selectedSource);
    }
  }, [selectedSource, church, navigate]);

  const onSetSource = useCallback((source: HouseholdOriginSource) => {
    return () => {
      setOnboardingHouseholdSurveyResponse({origin: source})
    }
  }, []);

  const options: Option[] = [
    {icon: <Icon key='cross' src='/images/cross.png'/>, prompt: 'From my church', source: HouseholdOriginSource.Church},
    {icon: <Icon key='pencil' src='/images/pencil.png'/>, prompt: 'From a school', source: HouseholdOriginSource.School},
    {icon: <Icon key='friend' src='/images/friend.png'/>, prompt: 'From a friend', source: HouseholdOriginSource.Friend},
    {icon: <Icon key='self' src='/images/magnifying-glass.png'/>, prompt: 'Found it myself', source:HouseholdOriginSource.Self},
  ];

  return (
    <Frame
      onboardingPage={OnboardingPages.SurveyDiscovery}
      mainContent={(
        <>
          <Message $widthInChars={22}>How did you hear about us?</Message>
          <ButtonContent>
            {options.map((option) => (
              <LeftAlignedButton
                key={option.source}
                onClick={onSetSource(option.source)}
                notPrimary={true}
                selected={option.source === selectedSource}
              >
                {option.icon}
                {option.prompt}
              </LeftAlignedButton>
            ))}
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!selectedSource}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const Icon = styled.img`
  width: 55px;
  height: 55px;
  margin: 10px 10px 10px 20px;
`;
