export enum OnboardingPages {
  AllowNotifications = 'allow-notifications',
  Awesome = 'awesome',
  ChurchChoice = 'church-choice',
  ChurchWelcome = 'church-welcome',
  CustomMusicUploadSongName = 'custom-music-upload-song-name',
  CustomMusicUploadPsalmNumber = 'custom-music-upload-psalm-number',
  CustomMusicUpload = 'custom-music-upload',
  Demo = 'demo',
  Download = 'download',
  HouseholdStart = 'household-start',
  HouseholdLastName = 'household-last-name',
  HouseholdEmail = 'household-email',
  HouseholdNotFound = 'household-not-found',
  HouseholdJoin = 'household-join',
  HouseholdCreate = 'household-create',
  HouseholdFind = 'household-find',
  HouseholdWelcome = 'household-welcome',
  InAppAnnouncement = 'in-app-announcement',
  InAppAdventNotification = 'in-app-advent-notification',
  SubscribeStart = 'subscribe-start',
  SubscribeFindHousehold = 'subscribe-find-household',
  SubscribePayment = 'subscribe-payment',
  SubscribeThankYou = 'subscribe-thank-you',
  SurveyDiscovery = 'survey-discovery',
  SurveyHouseholdSchedule = 'survey-household-schedule',
  SurveyConfidenceLevel = 'survey-confidence-level',
  SurveyExpectedUsage = 'survey-expected-usage',
  SurveyTimeInvestment = 'survey-time-investment',
  Questions = 'questions',
  ShareFriendsFamily = 'share-friends-family',
  ShareMusicLeader = 'share-music-leader',
  Start = 'start',
}

export enum Pages {
  Demo = 'demo',
  Favorites = 'favorites',
  Help = 'help',
  Library = 'library',
  Loading = 'loading',
  Settings = 'settings',
  ThisWeek = 'this-week',
  Today = 'today',
  AdminInvite = 'admin-invite',
  HouseholdInvite = 'church-household-invite',
  HouseholdCreation = 'household',
  Onboarding = 'onboarding',
  Privacy = 'privacy',
}

export function getOnboardingPath(page?: OnboardingPages) {
  return !page ? `/${Pages.Onboarding}` : `/${Pages.Onboarding}#${page}`;
}
