import {HymnalsManifest} from '../../common/model';
import {useEffect, useState} from 'react';
import * as server_api from '../../common/server_api';

export function useHymnalsManifest(): HymnalsManifest | undefined {
  const [hymnalsManifest, setHymnalsManifest] = useState<HymnalsManifest | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const serverManifest = await server_api.getHymnalsManifest();
        setHymnalsManifest(serverManifest);
      } catch {
        console.error(`Unable to retrieve global hymnals manifest`);
      }
    })();
  }, []);

  return hymnalsManifest;
}
