import {useEffect} from 'react';
import {useHousehold} from '../../data/use_household';
import {getPageFromLocation} from '../../util/path';
import {OnboardingPages, Pages} from '../../../common/pages';
import {getHymnFromUrl} from '../../shared';
import {useLocation, useNavigate} from 'react-router-dom';
import {navigateToOnboardingPage} from './common';

export function useDisplayDemoPage() {
  const {household} = useHousehold();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (household || getPageFromLocation(location) === Pages.Onboarding || getHymnFromUrl(location.search)) {
      return;
    }
    navigateToOnboardingPage(navigate, OnboardingPages.Demo);
  }, [household, location, navigate]);
}
