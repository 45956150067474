import {useCallback, useEffect, useState} from 'react';
import {Hymn, Sequencer, SequencerProps} from '../sequencer';
import {useChurch} from '../data/use_church';
import {localStorageGet, LocalStorageKey} from '../data/client_local_storage';
import {useLocalStorage} from '../data/use_local_storage';
import {useHymnalsManifest} from '../data/use_hymnals_manifest';
import {getSongSlugForHymn} from '../../common/paths';

const generalDefaultTempo = 100;

export function useSequencer({
  hymn,
  hymns,
  onHymnChange,
  onReadyToPlay,
  onNetworkError,
  setPlaying,
  onVerseChange,
  stopwatch,
  updateLocationSlider,
}: Omit<SequencerProps, 'setMidiFileTempo'> & {hymn: Hymn, hymns: Hymn[]}): {
  sequencer: Sequencer | undefined; // TODO(hewitt): this should *not* be passed back; instead pass accessor functions
  defaultTempo: number | undefined;
  setCurrentTempo: (tempo: number) => void;
  setSongIntroductionPercent: (value: number) => void;
} {
  const [sequencer, setSequencer] = useState<Sequencer | undefined>();
  const {church} = useChurch();
  const hymnalsManifest = useHymnalsManifest();
  const songSlug = getSongSlugForHymn(hymnalsManifest, hymn);
  const tempoOverride = songSlug ? church?.songTempos?.[songSlug] : undefined;
  const [midiFileTempo, setMidiFileTempo] = useState<number | undefined>(); // should reside in useMidiPlayer???
  const [currentTempo, setCurrentTempo] = useState(tempoOverride ?? undefined); // should reside in useMidiPlayer???
  const songIntroduction = localStorageGet(LocalStorageKey.SongIntroduction);
  const [voiceValues] = useLocalStorage(LocalStorageKey.VoiceValues);
  const defaultTempo = tempoOverride ?? midiFileTempo;

  useEffect(() => {
    if (songIntroduction) {}
    sequencer?.reset();
  }, [sequencer, songIntroduction]);

  useEffect(() => {
    if (voiceValues) {}
    // TODO(hewitt): may trigger unnecessary mp3 generation restart
    sequencer?.onVolumeChange();
  }, [sequencer, voiceValues]);

  useEffect(() => {
    const sequencer = new Sequencer(hymns);
    setSequencer(sequencer);
    return () => sequencer.destroy();
  }, [hymns]);

  useEffect(() => {
    sequencer?.setCurrentTempo(currentTempo ?? defaultTempo ?? generalDefaultTempo);
  }, [defaultTempo, sequencer, currentTempo]);

  useEffect(() => {
    sequencer?.setProps({
      onHymnChange,
      onReadyToPlay,
      onNetworkError,
      setPlaying,
      onVerseChange,
      stopwatch,
      setMidiFileTempo,
      updateLocationSlider,
    });
  }, [
    onHymnChange,
    onReadyToPlay,
    onNetworkError,
    setPlaying,
    onVerseChange,
    stopwatch,
    setMidiFileTempo,
    sequencer,
    updateLocationSlider,
  ]);

  const setSongIntroductionPercent = useCallback((value: number) => {
    if (sequencer) {
      sequencer.songIntroductionPercent = value;
    }
  }, [sequencer]);

  return {sequencer, defaultTempo, setCurrentTempo, setSongIntroductionPercent};
}
