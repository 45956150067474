import React, {ReactElement, useCallback, useState} from 'react';
import './player_page_options.css'
import './shared.css'
import {ReactComponent as BackArrow} from './assets/arrow-previous-left-icon.svg';
import {ReactComponent as Add} from './assets/add.svg';
import {ReactComponent as Remove} from './assets/remove.svg';
import {Hymn} from './sequencer';
import {LocalStorageKey} from './data/client_local_storage';
import {Favorite, TransposedSong} from '../common/model';
import {useLocalStorage} from './data/use_local_storage';
import {SongOptions} from './pages/settings/song_options';
import {SettingsDisplay} from './pages/settings/shared';
import {SettingsTransitionWrapper} from './shared';
import {Header} from './pages/header';
import {VerseCount} from './pages/settings/verse_count';
import {SongIntroductionSettingPage} from './pages/settings/song_introduction';


interface Props {
  back: () => void;
  hymn: Hymn;
}

export const PlayerPageOptions = ({back, hymn}: Props) => {
  const [display, setDisplay] = useState(SettingsDisplay.Settings);
  const keys = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"]
  const [transpositions, setTranspostions] = useLocalStorage(LocalStorageKey.KeyModulation);
  const initialKeyIndex = Math.max(0, keys.findIndex(key => key === hymn.key));

  const makeTransposition = useCallback((transposeValue: number = 0): TransposedSong => {
    return {songNumber: hymn.number, hymnalName: hymn.hymnal, transposeValue};
  }, [hymn]);

  const _equal = useCallback((lhs: Favorite, rhs: Favorite) => {
    return lhs.hymnalName === rhs.hymnalName && lhs.songNumber === rhs.songNumber;
  }, []);

  const getModulationValue = useCallback(() => {
    const transposition = transpositions?.filter(value => _equal(value, makeTransposition()))?.[0];
    return transposition?.transposeValue ?? 0;
  }, [_equal, makeTransposition, transpositions]);

  const keyIndex = (initialKeyIndex + getModulationValue() * 2 + 12) % 12;

  const wrapWithTransition = useCallback((
    title: string,
    component: ReactElement,
    page: SettingsDisplay,
    {suppressHeader}: {suppressHeader?: boolean} = {}
  ) => (
    <SettingsTransitionWrapper $left={(display === page) ? "0" : "100%"}>
      {!suppressHeader && <Header title={title} onBack={() => setDisplay(SettingsDisplay.Settings)}/>}
      {component}
    </SettingsTransitionWrapper>
  ), [display]);

  function renderVerseCount() {
    return wrapWithTransition(
      'Verse Count',
      <VerseCount back={() => {setDisplay(SettingsDisplay.Settings)}} />,
      SettingsDisplay.VerseCount
    );
  }

  function renderSongIntroduction() {
    return wrapWithTransition(
      'Song Introduction',
      <SongIntroductionSettingPage back={() => {setDisplay(SettingsDisplay.Settings)}} />,
      SettingsDisplay.SongIntroduction
    );
  }

  // transpositions other than the current song
  function otherTranspositions() {
    return transpositions.filter(value => !_equal(value, makeTransposition()));
  }

  function reset() {
    modulateKey(0); // fire an event to listeners that this song is being reset to the original key
    setTranspostions([...otherTranspositions()]); // now remove it from the list
  }

  function modulateKey(delta: number) {
    const newModulationValue = Math.min(6, Math.max(-6, getModulationValue() + delta));
    setTranspostions([
      ...otherTranspositions(),
      ...(newModulationValue === 0 ? [] : [makeTransposition(newModulationValue)]),
    ]);
  }

  function renderKeyModulation() {
    const sign = getModulationValue() > 0 ? '+' : '';
    const modulationValue = getModulationValue().toFixed(1);
    return (
      <div className='option'>
        <div id="keyModulationSetting" style={{display: "flex"}}>
          <div className='settingLabel'>Transpose Key</div>
          <div id="modulationValue">{`${sign}${modulationValue}`}</div>
        </div>

        <div id="modulationContainer">
          <div className="control" id="left">
            <div onClick={() => modulateKey(-0.5)}>
              <Remove className="addSubtractIcon"/>
            </div>
          </div>

          <div id="key">
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontWeight: 'bold',
            }}>
              {hymn.key && keys[keyIndex]}
            </div>
          </div>

          <div className="control" id="right">
            <div onClick={() => modulateKey(0.5)}>
              <Add className="addSubtractIcon"/>
            </div>

          </div>
          <button id="resetButton" onClick={() => reset()}>Reset</button>
        </div>
      </div>
    )
  }

  return (
    <div style={{width: '100%', display: "relative"}}>
      <div onClick={() => back()}>
        <BackArrow className="backArrow"/>
      </div>
      <div id="playerSettings">
        <SongOptions setDisplay={setDisplay}/>
        {renderKeyModulation()}
        {renderVerseCount()}
        {renderSongIntroduction()}
      </div>

    </div>
    )


};
