import autoBind from 'auto-bind';
import {
  createAudioContext,
  generateWavBlobForMIDI,
  loadInstrument,
  MP3GeneratorResult, MP3Inputs,
} from './util/instrument';
import {
  addListener,
  getDefaultValue,
  localStorageGet,
  LocalStorageKey,
  removeListener
} from "./data/client_local_storage";
import * as midiManager from "midi-file";
import {MidiData, MidiSetTempoEvent} from "midi-file";
import {DualAudioElement} from "./util/dual_audio_element";

const msBetweenMP3Refreshes = 500;
const microsecondsPerMinute = 60000000;
export const playbackRateThresholdLow = 0.9;
export const playbackRateThresholdHigh = 1.1;

export class MidiFilePlayer {
  audioContext = createAudioContext();
  initialTime: string | undefined = '0:00';
  mostRecentTick: number | undefined;
  mp3RefreshDebounceTimer: NodeJS.Timeout | undefined;
  mostRecentMP3RefreshTime: number;
  isMidiFileLoaded: boolean = false;
  isInstrumentLoaded?: boolean;
  playing?: boolean;
  midiFilePath: string;
  audioElement: DualAudioElement;
  songReady: () => void;
  onNetworkError: (response?: Response) => void;
  endOfFile: () => void;
  updateLocationSlider: (() => void) | undefined;
  voiceValues = getDefaultValue(LocalStorageKey.VoiceValues);
  modulationHalfSteps: () => number;
  private _midiData: MidiData | undefined;
  setMidiFileTempo: (tempo: number) => void;
  getCurrentTempo: () => number | undefined;
  currentMP3Inputs: MP3Inputs | undefined;
  get tempoOfCurrentMP3() { return this.currentMP3Inputs?.tempo };

  constructor({
    midiFilePath,
    songReady,
    onNetworkError,
    endOfFile,
    modulationHalfSteps,
    logAllEvents,
    setMidiFileTempo,
    getCurrentTempo,
    updateLocationSlider,
  }: {
    midiFilePath: string,
    songReady: () => void,
    onNetworkError: (response?: Response) => void,
    endOfFile: () => void,
    modulationHalfSteps: () => number,
    logAllEvents?: boolean,
    setMidiFileTempo: (tempo: number) => void,
    getCurrentTempo: () => number | undefined,
    updateLocationSlider?: () => void,
  }) {
    autoBind(this);

    this.midiFilePath = midiFilePath;
    this.audioElement = new DualAudioElement();
    this.songReady = songReady;
    this.onNetworkError = onNetworkError;
    this.endOfFile = endOfFile;
    this.setMidiFileTempo = setMidiFileTempo;
    this.getCurrentTempo = getCurrentTempo;
    this.updateLocationSlider = updateLocationSlider;

    this.audioElement.addEventListener("ended", this._onEndOfFile.bind(this));
    this.audioElement.addEventListener("canplaythrough", this._onCanPlayThrough.bind(this));

    if (logAllEvents) {
      this.audioElement.addEventListener('audioprocess', () => console.log('audioprocess'))
      this.audioElement.addEventListener('canplay', () => console.log('canplay'));
      this.audioElement.addEventListener('canplaythrough', () => console.log('canplaythrough'));
      this.audioElement.addEventListener('complete', () => console.log('complete'));
      this.audioElement.addEventListener('durationchange', () => console.log('durationchange'));
      this.audioElement.addEventListener('emptied', () => console.log('emptied'));
      this.audioElement.addEventListener('ended', () => console.log('ended'));
      this.audioElement.addEventListener('loadeddata', () => console.log('loadeddata'));
      this.audioElement.addEventListener('loadedmetadata', () => console.log('loadedmetadata'));
      this.audioElement.addEventListener('loadstart', () => console.log('loadstart'));
      this.audioElement.addEventListener('pause', () => console.log('pause'));
      this.audioElement.addEventListener('play', () => console.log('play'));
      this.audioElement.addEventListener('playing', () => console.log('playing'));
      this.audioElement.addEventListener('ratechange', () => console.log('ratechange'));
      this.audioElement.addEventListener('seeked', () => console.log(`seeked ${this.audioElement.currentTime}`));
      this.audioElement.addEventListener('seeking', () => console.log('seeking'));
      this.audioElement.addEventListener('stalled', () => console.log('stalled'));
      this.audioElement.addEventListener('suspend', () => console.log('suspend'));
      this.audioElement.addEventListener('timeupdate', () => console.log('timeupdate'));
      this.audioElement.addEventListener('volumechange', () => console.log('volumechange'));
      this.audioElement.addEventListener('waiting', () => console.log('waiting'));
    }

    this.mostRecentMP3RefreshTime = Date.now();
    this._loadMidiFile();

    void loadInstrument(async () => {
      await this._refreshMP3();
      this.isInstrumentLoaded = true;
      if (this.isMidiFileLoaded) {
        this.songReady();
      }
    });

    this._refreshVoiceValues();
    addListener(LocalStorageKey.VoiceValues, this._refreshVoiceValues);

    this.modulationHalfSteps = modulationHalfSteps;
  }

  private _refreshVoiceValues() {
    this.voiceValues = localStorageGet(LocalStorageKey.VoiceValues);
  }

  private _onEndOfFile() {
    this.endOfFile()
  }

  private _onCanPlayThrough() {
    if (!this.isMidiFileLoaded) {
      this.isMidiFileLoaded = true;
      if (this.isInstrumentLoaded) {
        this.songReady();
      }
    }
  }

  restart() {
    this.audioElement.currentTime = 0;
    this.updateLocationSlider?.();
  }

  setLocationPercentage(percent: number) {
    this.audioElement.currentTime = this.audioElement.duration * (percent / 100);
  }

  getCurrentPercent(): number | undefined {
    const duration = this.duration;
    if (duration === undefined || duration === 0) {
      return undefined;
    }
    return (this.audioElement.currentTime / duration) * 100;
  }

  setNewTime(value: number) {
    const sliderValue = value / 100;
    const newTime = sliderValue * this.audioElement.duration;
    this.audioElement.currentTime = newTime;
  }

  private _debouncedMP3Refresh() {
    const msSinceLastMP3Refresh = Date.now() - this.mostRecentMP3RefreshTime;
    if (this.mp3RefreshDebounceTimer) {
      clearTimeout(this.mp3RefreshDebounceTimer);
    }
    this.mp3RefreshDebounceTimer = setTimeout(
      this._refreshMP3,
      Math.max(0, msBetweenMP3Refreshes - msSinceLastMP3Refresh),
    );
  }

  onVolumeChange() {
    this._debouncedMP3Refresh();
  }

  onTempoChange() {
    const currentTempo = this.getCurrentTempo();
    let playbackRate: number | undefined;
    if (!this.tempoOfCurrentMP3) {
      playbackRate = undefined;
    } else {
      playbackRate = currentTempo && this.tempoOfCurrentMP3 && currentTempo / this.tempoOfCurrentMP3;
    }
    if (localStorageGet(LocalStorageKey.EnableSmoothTempoAdjustment) && this.playing && playbackRate &&
      playbackRate >= playbackRateThresholdLow &&
      playbackRate <= playbackRateThresholdHigh
    ) {
      this.audioElement.playbackRate = playbackRate;
    } else {
      this.audioElement.playbackRate = 1;
      this.onVolumeChange();
    }
  }

  private get _finishedLoading() {
      return this.isInstrumentLoaded && this.isMidiFileLoaded
  }

  get duration(): number | undefined { // in seconds
    if (Number.isNaN(this.audioElement.duration)) {
      return undefined;
    }
    return this.audioElement.duration;
  }

  play() {
    void this.audioElement.play();
    this.playing = true;
    this.mostRecentTick = undefined;
  }

  pause() {
    this.audioElement.pause();
    this.playing = false;
  }

  private _loadMidiFile() {

    const loadArrayBuffer = async (buffer: ArrayBuffer) => {
      try {
        const midiArray = new Uint8Array(buffer);
        this._midiData = midiManager.parseMidi(midiArray);
        const tempoEvent = this._midiData.tracks[0].find(event => event.type === 'setTempo') as MidiSetTempoEvent | undefined;
        if (tempoEvent) {
          this.setMidiFileTempo(tempoEvent.microsecondsPerBeat ? Math.round(microsecondsPerMinute / tempoEvent.microsecondsPerBeat) : 100);
        }
        // wait for React tempos to settle down before asking for current tempo
        setTimeout(this._refreshMP3);
        this.initialTime = '0:00';
      } catch (error: any) {
        console.log(`Error parsing MIDI file ${this.midiFilePath}: ${error.message}`);
        this.onNetworkError();
      }
    }

    fetch(this.midiFilePath)
        .then(
          response => {
            if (!response.ok) {
              this.onNetworkError(response);
              return;
            }
            response.arrayBuffer().then(buffer => loadArrayBuffer(buffer));
          },
          reason => console.log(reason)
        );
  }

  private async _refreshMP3() {
    const tempo = this.getCurrentTempo();
    if (!this._midiData || !tempo) {
      return;
    }
    // have any inputs changed since last MP3 generation?
    const newInputs = {tempo, modulationHalfSteps: this.modulationHalfSteps(), voiceValues: this.voiceValues};
    const previousInputs = this.currentMP3Inputs;
    if (JSON.stringify(newInputs) === JSON.stringify(previousInputs)) {
      return;
    }
    this.currentMP3Inputs = newInputs; // optimistically guard against re-entrancy
    const {audioBlob, result} = await generateWavBlobForMIDI(
      this._midiData,
      newInputs,
    );
    if (result === MP3GeneratorResult.Failure) {
      this.onNetworkError();
      this.currentMP3Inputs = previousInputs;
      return;
    }
    if (!audioBlob) {
      this.currentMP3Inputs = previousInputs;
      return;
    }
    this.audioElement.src = audioBlob;
    this.mp3RefreshDebounceTimer = undefined;
    this.mostRecentMP3RefreshTime = Date.now();
  }

  destroy() {
    removeListener(LocalStorageKey.VoiceValues, this._refreshVoiceValues);

    // stop music when back button is pressed
    if (this.playing) {
      this.pause();
    }
  }
}