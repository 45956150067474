import * as server_api from "../../common/server_api"
import {localStorageGet, LocalStorageKey, localStorageRemove, localStorageSet} from "./client_local_storage";
import {SongView} from '../../common/model';

class SongViews {
  add(songView: SongView) {
    const cachedSongViews = localStorageGet(LocalStorageKey.SongViews);
    cachedSongViews.push(songView);
    localStorageSet(LocalStorageKey.SongViews, cachedSongViews)
  }

  async uploadToServer(): Promise<void> {
    const cachedSongViews = localStorageGet(LocalStorageKey.SongViews);
    if (cachedSongViews.length === 0) {
      return;
    }
    await server_api.reportSongViews(cachedSongViews);
    // clear song views if upload was successful
    // otherwise an exception will be thrown by server_api and we will try again next time
    localStorageRemove(LocalStorageKey.SongViews);
  }
}

export const songViews = new SongViews();
