import {thisWeekDirName} from '../../common/paths';
import fetch from 'node-fetch';
import {SongListDeprecated} from '../../common/model';
import {localStorageGet, LocalStorageKey, localStorageSet} from './client_local_storage';

export const DemoChurch = 'Demo Church (Meridian ID)';

export async function synchronizeDemoSongList() {
  // ignore network errors
  try {
    const demoManifest = `/${thisWeekDirName}/${DemoChurch}.json`;
    const response = await fetch(demoManifest);
    const demoSongs = await response.json() as SongListDeprecated[];
    const previousDemoSongs = localStorageGet(LocalStorageKey.DemoSongList);

    // only update storage if something changed
    if (JSON.stringify(demoSongs) !== JSON.stringify(previousDemoSongs)) {
      localStorageSet(LocalStorageKey.DemoSongList, demoSongs);
    }
  } catch { }
}
