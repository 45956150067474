import {monkeyPatchPushState} from "./use_location";
import {clearRetryCountAfterSuccessfulLoad, reportCrash} from "./crash";
import {generateMidiNoteNames, loadSoundfont} from "./instrument";
import {registerUserTokenCallbacks} from "../../common/server_api";
import {
  Guest,
  localStorageGet,
  LocalStorageKey,
  localStorageSet,
  registerForLocalStorageEvents
} from "../data/client_local_storage";
import {
  isInsideAppleAppStoreApp,
  migrateUserChurchToHouseholdChurch,
  requestIOSAppVersion,
  updateIsInsideGooglePlayApp
} from './billing';
import {synchronizeChurchWithServer, synchronizeWeeklySongLists} from '../data/use_church';
import {synchronizeAppConfigWithServer} from '../data/app_config';
import {forwardConsoleLogToIOS} from './ios_logging';
import {recordConsoleLog} from './logging';
import {synchronizeHouseholdWithServer} from '../data/use_household';
import {registerPushNotificationHandlers} from './push_notifications';
import {synchronizeDemoSongList} from '../data/demo_song_list';
import {synchronizeHouseholdSingingPlan} from '../data/use_household_singing_plan';
import {recordBundleHash} from './app_upgrade';
import {recordUserHousehold} from '../data/use_user_attributes';

export function initializeApp(navigate: (to: string) => Promise<void>) {
  recordBundleHash();
  registerServiceWorker();
  registerErrorHandler();

  registerForLocalStorageEvents();
  monkeyPatchPushState();
  clearRetryCountAfterSuccessfulLoad();
  void loadSoundfont();
  generateMidiNoteNames();
  registerUserTokenCallbacks({
    userCallback: () => localStorageGet(LocalStorageKey.UserToken),
    householdCallback: () => localStorageGet(LocalStorageKey.Household)?.token,
  });

  void migrateUserChurchToHouseholdChurch();
  void recordUserHousehold();

  initializeForMobile();
  recordConsoleLog();
  void synchronizeWithServer();
  registerDefaultUser();
  registerPushNotificationHandlers(navigate);
}

// TODO(hewitt): review this when we support fully offline
/*
IF YOU MODIFY THE APP VERSIONING SYSTEM IN ANY OF THE FOLLOWING WAYS:
- change the variable name
- change the version number to more than one decimal place ('x.y')
    - 'x.yz'
    - 'x.y.z'
    - 'xy.z'
YOU MUST MODIFY 'test-build.js' LINE 98 AND OR LINE 99 ACCORDINGLY
*/

function registerServiceWorker() {
  if ('serviceWorker' in navigator && window.location.host !== 'localhost') {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('./service-worker.js')
        .then(function (registration) {
          // registration was successful
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function (err) {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        });
    });
  }
}

// in case we ever want to revoke the service worker to completely disable offline support
export function unregisterServiceWorker() {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      void registration.unregister()
    }
  })
}

// TODO(hewitt): review when we implement fully offline
function registerErrorHandler() {
  window.addEventListener('error', function (event: ErrorEvent) {
    if (event.error.hasBeenCaught !== undefined) {
      return false;
    }
    event.error.hasBeenCaught = true;
    const message = {
      message: event.message,
      url: event.filename,
      line: event.lineno,
      column: event.colno,
      error: JSON.stringify(event.error)
    }

    // @ts-ignore
    if (window.webkit) {
      // @ts-ignore
      window.webkit.messageHandlers.error.postMessage(message);
    } else {
      console.log("Error:", message);
    }
  });

  window.addEventListener("error", reportCrash);
}

function initializeForMobile() {
  void updateIsInsideGooglePlayApp();
  if (isInsideAppleAppStoreApp()) {
    requestIOSAppVersion();
    forwardConsoleLogToIOS();
  }
}

async function synchronizeWithServer() {
  // properly order initialization
  // each clause can independently fail without preventing subsequent clauses from running
  try { await synchronizeHouseholdWithServer({force: true}) } catch {}
  try { await synchronizeChurchWithServer({force: true}) } catch {}
  try { await synchronizeWeeklySongLists() } catch {}
  try { await synchronizeHouseholdSingingPlan() } catch {}
  try { await synchronizeDemoSongList() } catch {}
  try { await synchronizeAppConfigWithServer() } catch {}
}

function registerDefaultUser() {
  const user = localStorageGet(LocalStorageKey.User);
  if (!user) {
    localStorageSet(LocalStorageKey.User, Guest);
  }
}
