import {HouseholdSettingsPage} from './settings/household_settings_page';
import {Header} from './header';
import React, {useCallback, useState} from 'react';
import {alert, confirm} from '../util/confirm';
import {useHousehold} from '../data/use_household';
import {Household, HouseholdStatus} from '../../common/model';
import {localStorageGet, LocalStorageKey} from '../data/client_local_storage';
import {isValidEmail} from '../util/billing';

export const HouseholdCreationPage = () => {

  const {household, setHousehold} = useHousehold();

  const [lastName, setLastName] = useState(household?.familyLastName);
  const [email, setEmail] = useState(household?.householdEmail);

  const confirmHousehold = useCallback(async () => {

    //make sure email is not already in use

    // if last name is undefined or empty, alert for valid last name
    if (lastName === undefined || lastName === '') {

      await alert({
        confirmation:
          <span>
            please enter a last name
          </span>
      })
      return
    }

    // if email is undefined, empty, or invalid, alert for valid email
    if (email === undefined || email === '' || !isValidEmail(email)) {

      await alert({
        confirmation:
          <span>
            please enter a valid email
          </span>
      })
      return
    }

    if (await confirm({
      confirmation:
        <span>
          Are you sure you wish to create a new household?<br/>
          Last Name: {lastName}<br/>
          Email: {email}
        </span>
    })) {
      console.log('create household');

      const church = localStorageGet(LocalStorageKey.Church);
      // make new household token
      const householdToken = '';

      const newHousehold: Household = {
        token: householdToken,
        familyLastName: lastName,
        householdEmail: email,
        churchId: church?.id,
        status: HouseholdStatus.Unsubscribed,  // default status for new household is unsubscribed?
      }

      setHousehold(newHousehold);

      //window.location.href = '/';
    }

  }, [lastName, email, setHousehold])

  return (
    <>
      <Header title={'Household'} householdCreation={true} />
      <HouseholdSettingsPage
        visible={true}
        householdCreation={true}
        confirmHousehold={confirmHousehold}
        setLastName={setLastName}
        setEmail={setEmail}
      />
    </>
  )
}