import {Hymn} from '../sequencer';
import {HymnalManifest} from '../../common/model';
import {customMusicDir, musicManifestFilename} from '../../common/paths';
import {useEffect, useState} from 'react';
import {getHymnsFromHymnalManifest} from './use_hymnals';

const customMusicManifestPath = `/${customMusicDir}/${musicManifestFilename}`;

export type CustomMusic = {[organizationId: string]: Hymn[]};

function getOrganizationId(organizationKey: string): string {
  const id = organizationKey.match(/^(?<id>\d+)/)?.groups?.id;
  if (!id) {
    throw new Error(`Unable to parse ID from organization key: ${organizationKey}`);
  }
  return id;
}

export function useCustomMusic(): CustomMusic | undefined {
  const [customMusic, setCustomMusic] = useState<CustomMusic | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const customMusicManifest = await fetch(customMusicManifestPath).then(res => res.json());
        const churchManifests: HymnalManifest[] = await Promise.all(Object.keys(customMusicManifest)
          .map(key => fetch(`/${customMusicDir}/${key}/${musicManifestFilename}`).then(res => res.json())));
        const hymnLists = Array.from(Object.keys(customMusicManifest).entries())
          .map(([index, key]) => [
            getOrganizationId(key),
            getHymnsFromHymnalManifest(key, churchManifests[index], {basePath: `/${customMusicDir}`})
          ]);
        setCustomMusic(Object.fromEntries(hymnLists));
      } catch (err: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to download custom music: ${err}`);
      }
    })();
  }, []);

  return customMusic;
}
