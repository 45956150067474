import assert from "assert";

export type DateString = string; // "YYYY-MM-DD"

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export function getMonthName(date: Date): string {
  switch (date.getMonth()) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      throw new Error(`ERROR: Unexpected month: ${date.getMonth()}`);
  }
}

export function createDateString(year: number, month: number, day: number): DateString {
  const yearString = year.toString();
  const monthString = month.toString().padStart(2, '0');
  const dayString = day.toString().padStart(2, '0');
  return `${yearString}-${monthString}-${dayString}`;
}

export function dateStringFromDate(date: Date): DateString {
  // note that month is zero based
  return createDateString(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

export function splitDateString(date: DateString): {year: number, month: number; day: number} | undefined {
  const match = date.match(/^(?<year>\d\d\d\d)-(?<month>\d\d)-(?<day>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {year, month, day} = match.groups;
  // note that month is zero based
  return {year: Number(year), month: Number(month) - 1, day: Number(day)};
}

export function validateDateString(date: DateString) {
  assert(splitDateString(date), `Malformed date string "${date}", expecting "YYYY-MM-DD"`);
}

export function dateFromString<T extends DateString | undefined>(date: T): T extends DateString ? Date : undefined
export function dateFromString(date: DateString | undefined): Date | undefined {
  if (!date) {
    return;
  }
  const match = splitDateString(date);
  if (!match) {
    return;
  }
  return new Date(match.year, match.month, match.day);
}

export function getDayOfWeekFromDateString(dateString: DateString): string {
  const date = dateFromString(dateString);
  switch (date.getDay()) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
   case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      throw new Error(`Invalid day of week ${date.getDay()} for date string ${dateString}`);
  }
}

// Gives the date of the specified day of the week relative to the current date.
// Thus, if today is 11/27/2024, a Wednesday, then asking for a Tuesday (dayIndex = 2) will yield 11/26/2024.
export function getDateOfDay(dayIndex: number, {treatSundayAsLast}: {treatSundayAsLast?: boolean} = {}): DateString {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay() + dayIndex - (treatSundayAsLast && date.getDay() === 0 ? 7 : 0));
  return dateStringFromDate(date);
}

export function thisSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(date.getDay() === 0 ? 0 : 7 - date.getDay(), forDate);
}

export function nextSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(7 - date.getDay() + (date.getDay() === 0 ? 0 : 7), forDate);
}

export function lastSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(-date.getDay() - (date.getDay() === 0 ? 7 : 0), forDate);
}

function getDateFromOffset(days: number, forDate: DateString | undefined): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  date.setDate(date.getDate() + days);
  return dateStringFromDate(date);
}

export function addDayOffset(dateString: DateString, days: number): DateString {
  const date = dateFromString(dateString);
  date.setDate(date.getDate() + days);
  return dateStringFromDate(date);
}

export function getDayOfWeek(date: DateString): DayOfWeek {
  return dateFromString(date).getDay();
}

export function thisMonthDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return createDateString(date.getFullYear(), date.getMonth() + 1, 1);
}

export function todayDate(): DateString {
  return dateStringFromDate(new Date());
}
