import React, {useCallback} from 'react';
import {Frame} from './frame';
import {ButtonContent, Message, navigateToOnboardingPage} from './common';
import {Button, LeftAlignedButton} from './button';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

interface Option {
  prompt: string;
  timeInvestment: number;
}

export const SurveyTimeInvestment = () => {
  const navigate = useNavigate();

  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdStart);
  }, [navigate]);

  const options: Option[] = [
    {prompt: '5 min/day', timeInvestment: 5},
    {prompt: '10 min/day', timeInvestment: 10},
    {prompt: '15 min/day', timeInvestment: 15},
  ];

  const [selectedTimeInvestment, setSelectedTimeInvestment] = useLocalStorage(LocalStorageKey.OnboardingTimeInvestment);

  const onChooseOption = useCallback((timeInvestment: number) => {
    return () => {
      setSelectedTimeInvestment(timeInvestment);
    };
  }, [setSelectedTimeInvestment]);

  return (
    <Frame
      onboardingPage={OnboardingPages.SurveyTimeInvestment}
      mainContent={(
        <>
          <Message $widthInChars={16}>How much time can you invest?</Message>
          <ButtonContent>
            {
              options.map((option) => (
                <LeftAlignedButton
                  key={option.timeInvestment}
                  onClick={onChooseOption(option.timeInvestment)}
                  notPrimary={true}
                  selected={option.timeInvestment === selectedTimeInvestment}
                >
                  <OptionWrapper>
                    {option.prompt}
                  </OptionWrapper>
                </LeftAlignedButton>
              ))
            }
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
