import {useEffect} from 'react';
import * as server_api from '../../common/server_api';
import {
  Guest,
  localStorageGet,
  LocalStorageKey,
  localStorageRemove,
  localStorageSet
} from '../data/client_local_storage';
import {AppConfig, HouseholdStatus, PaymentProcessor} from '../../common/model';
import {synchronizeChurchWithServer} from '../data/use_church';
import {Device, getDevice, getPlatform, Platform} from './platform';
import {useLocalStorage} from '../data/use_local_storage';
import {useAppConfig} from '../data/app_config';

const AppleSubscriptionInfoTimeoutMS = 10000;

export const IOSAppVersionWithPushNotifications = 4.0;

export enum ProductId {
  HouseholdAnnualSubscription = 'com.crescendosw.ensemble.household.annual',
  HouseholdMonthlySubscription = 'com.crescendosw.ensemble.household.monthly',
}

// Stripe payment links
export const StripePaymentLinkAnnual = 'https://buy.stripe.com/dR6eVX5TZfUngy48wx';
export const StripePaymentLinkMonthly = 'https://buy.stripe.com/8wMaFH1DJ9vZepW000';
export const StripePaymentLinkAnnualDonationLevel1 = 'https://buy.stripe.com/9AQaFH96b0Zt95CfZ0';
export const StripePaymentLinkAnnualDonationLevel2 = 'https://buy.stripe.com/dR6aFH82723xdlS003';
export const StripePaymentLinkMonthlyDonationLevel1 = 'https://buy.stripe.com/7sIdRT5TZ4bF81y4gk';
export const StripePaymentLinkMonthlyDonationLevel2 = 'https://buy.stripe.com/00gg016Y3dMf95C6ot';
export const StripePaymentLinkAnnualTest = 'https://buy.stripe.com/test_28ocOAguQ415d6oaEF';

export const AppStoreUrl = 'https://apps.apple.com/us/app/sing-your-part/id1483413949?itsct=apps_box_link&itscg=30200';
export const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=singyourpart.app.twa';

// Apple product IDs
export enum AppleProductId {
  Annual = 'com.crescendosw.ensemble.household.annual',
  Monthly = 'com.crescendosw.ensemble.household.monthly',
  AnnualDonationLevel1 = '100.Annual.Gift.Subscription',
  AnnualDonationLevel2 = '250.annual.gift.subscription',
}

// Google product IDs
export enum GoogleProductId {
  Annual = 'annualfamily',
  Monthly = 'montlyfamily', // note the misspelling of "monthly"
}

export function isInsideAppleAppStoreApp(): boolean {
  // @ts-ignore
  return Boolean(window.webkit);
}

let isInsideGooglePlayAppCached = false;

export async function updateIsInsideGooglePlayApp() {
  // @ts-ignore
  if (!window.getDigitalGoodsService) {
    isInsideGooglePlayAppCached = false;
    return;
  }
  try {
    // @ts-ignore
    const digitalGoodsService = await window.getDigitalGoodsService("https://play.google.com/billing");
    isInsideGooglePlayAppCached = Boolean(digitalGoodsService);
    if (isInsideGooglePlayAppCached) {
      console.log(`*** INSIDE GOOGLE PLAY APP ***`);
    }
  } catch {}
}

export function isInsideGooglePlayApp(): boolean {
  // @ts-ignore
  return isInsideGooglePlayAppCached;
}

export function isInsideMobileApp(): boolean {
  return isInsideAppleAppStoreApp() || isInsideGooglePlayApp();
}

export function doesAppVersionSupportSubscriptions(): boolean {
  // @ts-ignore
  return Boolean(window.webkit?.messageHandlers?.subscribe);
}

export function useAppUpdateNeeded(): boolean {
  const [iOSAppVersion] = useLocalStorage(LocalStorageKey.IOSAppVersion);
  const iOSAppNewestReleasedVersion = useAppConfig(AppConfig.IOSAppNewestReleasedVersion);
  return isInsideAppleAppStoreApp() && (!iOSAppVersion || iOSAppVersion < iOSAppNewestReleasedVersion);
}

export function getMonthlySubscriptionPrice() {
  return isInsideMobileApp() ? 4.99 : 3.99
}

export function getAnnualSubscriptionPrice() {
  return isInsideMobileApp() ? 44.99 : 39.99
}

export function isInsideMobileBrowser() {
  return getPlatform() === Platform.Mobile && !isInsideMobileApp();
}

export interface AppleSubscriptionInfo {
  appAccountToken?: string;
  productId?: string;
  expirationDate?: string;
}

// sets up a callback on globalThis that is used by the iOS app to forward subscription info
export function useSetupIOSSubscriptionInfoCallback(callback: (subscriptionInfo: AppleSubscriptionInfo) => void) {
  useEffect(() => {
    // @ts-ignore
    globalThis.receiveSubscriptionInfo = (subscriptionInfo: AppleSubscriptionInfo) => {
      callback(subscriptionInfo);
    }
    // @ts-ignore
    return () => { delete globalThis.receiveSubscriptionInfo; };
  }, [callback]);
}

// sets up a callback on globalThis that is used by the iOS app to forward subscription info
export function useSetupIOSCanMakePurchasesCallback(callback: (canMakePurchases: boolean) => void) {
  useEffect(() => {
    // @ts-ignore
    globalThis.receiveCanMakePurchases = (canMakePurchases: boolean) => {
      callback(canMakePurchases);
    }
    // @ts-ignore
    return () => { delete globalThis.receiveCanMakePurchases; };
  }, [callback]);
}

export function requestAppleCanMakePurchases(): void {
  // @ts-ignore
  window.webkit?.messageHandlers?.requestCanMakePurchases?.postMessage({});
}

// a callback to the iOS app that requests subscription info, which is passed back via receiveSubscriptionInfo above
export function requestAppleSubscriptionInfo(): void {
  // @ts-ignore
  window.webkit?.messageHandlers?.requestSubscriptionInfo?.postMessage({});
}

export function subscribeApple(productId: ProductId, householdToken: string) {
  // @ts-ignore
  window.webkit?.messageHandlers?.subscribe?.postMessage({productId, householdToken});
}

export function manageAppleSubscription() {
  // @ts-ignore
  window.webkit?.messageHandlers?.manageSubscription?.postMessage({});
}

// should only be called from synchronizeHousehold
export async function synchronizeMobileHouseholdStatus() {
  try {
    const household = localStorageGet(LocalStorageKey.Household);
    if (isInsideMobileApp()) {
      await synchronizeClientSubscription();
    }

    if (isInsideAppleAppStoreApp()) {
      const clientSubscription = localStorageGet(LocalStorageKey.ClientSubscription);
      if (
        clientSubscription?.subscriptionExpirationTimestamp &&
        clientSubscription.subscriptionExpirationTimestamp > Date.now()
      ) {
        // prefer the household token associated with the iCloud account
        // automatically configures new devices to be part of a subscribed household
        const token = clientSubscription?.appAccountToken ?? household?.token;
        if (token) {
          localStorageSet(LocalStorageKey.Household, {...household, token, status: HouseholdStatus.Subscribed});
        }
        return;
      }
    } else if (isInsideGooglePlayApp() && household?.token) {
      // @ts-ignore
      const service = await window.getDigitalGoodsService("https://play.google.com/billing");
      const existingPurchases = await service.listPurchases();
      if (existingPurchases.length > 0) {
        // Note: Purchases signature: {itemId: 'annualfamily' | 'montlyfamily', purchaseToken: string}
        // From W3C docs on purchaseToken:
        //   purchaseToken is an abitrary token representing a purchase as generated by the serviceProvider.
        //   It is intended to be able to be used to verify the purchase by contacting the service provider
        //   directly (not part of the Digital Goods API).
        localStorageSet(LocalStorageKey.Household, {...household, status: HouseholdStatus.Subscribed});
        return;
      }
    }
  } catch {}
}

export function requestIOSAppVersion() {
  if (isInsideAppleAppStoreApp()) {
    // @ts-ignore
    globalThis.receiveAppVersion = ({appVersion}: {appVersion: number})=> {
      localStorageSet(LocalStorageKey.IOSAppVersion, appVersion);
    }
    // @ts-ignore
    window.webkit?.messageHandlers?.getAppVersion?.postMessage({});
  }
}

// retrieves the client subscription info & stores it
// should only be called from synchronizeMobileHouseholdStatus
async function synchronizeClientSubscription() {
  if (isInsideGooglePlayApp()) {
    try {
      // TODO(hewitt): is the current household subscribed via Google Play -> how to connect the household ID?
      //               also how to extract the household token from the Google Play subscription
    } catch {
      console.log(`Error checking `)
    }
    return;
  }

  if (!isInsideAppleAppStoreApp()) {
    return;
  }

  let resolver: ((value: boolean | PromiseLike<boolean>) => void) | undefined;
  let appleSubscriptionInfo: AppleSubscriptionInfo | undefined;

  function parseAppleDateAsTimestamp(dateString?: string): number | undefined {
    // date string has format "2024-09-27 18:24:42 +0000"
    if (!dateString) {
      return;
    }
    const match = dateString.match(/^(?<year>\d+)-(?<month>\d+)-(?<day>\d+) .*/);
    if (!match || !match.groups) {
      console.log(`ERROR: could not parse Apple date "${dateString}"`);
      return;
    }
    const {year, month, day} = match.groups;
    return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
  }

  // @ts-ignore
  globalThis.receiveSubscriptionInfo = (subscriptionInfo: AppleSubscriptionInfo) => {
    appleSubscriptionInfo = subscriptionInfo;
    resolver?.(true);
  }
  try {
    requestAppleSubscriptionInfo();

    await new Promise<boolean>((res) => {
      resolver = res;
      setTimeout(res, AppleSubscriptionInfoTimeoutMS);
    });

    if (!appleSubscriptionInfo || !appleSubscriptionInfo.expirationDate) {
      localStorageRemove(LocalStorageKey.ClientSubscription);
      return;
    }

    const subscription = {
      paymentProcessor: PaymentProcessor.Apple,
      appAccountToken: appleSubscriptionInfo.appAccountToken,
      subscriptionExpirationTimestamp: parseAppleDateAsTimestamp(appleSubscriptionInfo.expirationDate),
      productId: appleSubscriptionInfo.productId,
    };
    localStorageSet(LocalStorageKey.ClientSubscription, subscription);
  } finally {
    // @ts-ignore
    delete globalThis.receiveSubscriptionInfo;
  }
}

export function clearStoredHouseholdInfo() {
  // does not clear household token
  let household = localStorageGet(LocalStorageKey.Household);
  delete household?.familyLastName;
  delete household?.householdEmail;
  localStorageSet(LocalStorageKey.Household, household);

  localStorageRemove(LocalStorageKey.Church)

  // TODO(hewitt): Remove 9/2024 - Update church based on billing id & church name
  localStorageRemove(LocalStorageKey.HouseholdChurchId);
  localStorageRemove(LocalStorageKey.HouseholdChurchName);
}

// TODO(hewitt): Remove 8/1/2024
export async function migrateUserChurchToHouseholdChurch() {
  if (localStorageGet(LocalStorageKey.HouseholdChurchId) || localStorageGet(LocalStorageKey.Church)) {
    return;
  }
  const user = localStorageGet(LocalStorageKey.User);
  if (!user || user === Guest) {
    return;
  }
  try {
    const church = await server_api.getUserChurch();
    if (!church || !church.id || !church.name || !church.location) {
      return;
    }
    localStorageSet(LocalStorageKey.Church, church);
    localStorageSet(LocalStorageKey.PushChurch, true);
    await synchronizeChurchWithServer();
  } catch { }
}

export function isValidEmail(email: string | undefined) {

  if(email === undefined) {
    return false;
  }

  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function getAppStoreUrl(): string | undefined {
  if (getDevice() === Device.iOS) {
    return AppStoreUrl;
  } else if (getDevice() === Device.Android) {
    return GooglePlayUrl;
  }
}
