import {ReactComponent as BackArrow} from '../assets/arrow-previous-left-icon.svg';
import React, {useCallback, useEffect, useState} from 'react';
import {RequestSongPage, requestSongPageDialog} from './request_song_page';
import {ensureExists} from '../../common/util';
import {Document, Page} from 'react-pdf';
import {Hymn} from '../sequencer';
import useWindowDimensions from '../util/useWindowDimensions';
import {encodeMusicURI, generateFileName} from '../util/path';
import {hymnalsPDFDir} from '../../common/paths';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from 'styled-components/macro';
import {HymnIssue} from '../../common/model';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {Spinner} from '../util/spinner';

interface Props {
  hymn: Hymn;
  issue?: HymnIssue;
  onClose: () => void;
  isPDFVisible: boolean;
}

export function PdfViewerPage({hymn, issue, onClose, isPDFVisible}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>();
  const windowDimensions = useWindowDimensions();
  const pdfFile = generateFileName(hymn) + '.pdf';
  const basePath = hymn.basePath ?? `/${hymnalsPDFDir}`;
  const pdfPath = `${basePath}/${encodeMusicURI(ensureExists(hymn.hymnal))}/${encodeMusicURI(pdfFile)}`;
  const {isInternalUser} = useUserAttributes();
  const [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf] =
    useLocalStorage(LocalStorageKey.RequestSongDisplayedForPDF);

  useEffect(() => {
    void (async () => {
      // only show modal if .mid file is missing and .pdf file loaded successfully
      if (issue !== HymnIssue.Missing || !numPages || requestSongDisplayedForPdf[hymn.hymnal]?.[hymn.number]) {
        return;
      }
      setRequestSongDisplayedForPdf({
        ...requestSongDisplayedForPdf, [hymn.hymnal]: {
          ...requestSongDisplayedForPdf[hymn.hymnal], [hymn.number]: true
        }
      });
      await requestSongPageDialog({
        confirmation: {
          issue,
          hymnalName: ensureExists(hymn.hymnal),
          songNumber: hymn.number,
        }
      });
    })();
  }, [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf, hymn.hymnal, hymn.number, issue, numPages]);

  const onLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
    console.log(`success: ${numPages}`);
    setNumPages(numPages);
  }, []);

  const onLoadError = useCallback((error: Error) => {
    setError(error);
    console.log(error.message);
  }, []);

  function renderLoadingPage() {
    return (
      <LoadingWrapper>
        <LoadingMessage>
          Loading sheet music...
        </LoadingMessage>
        <Spinner/>
      </LoadingWrapper>
    );
  }

  const isRequestPageVisible = (hymn.issue === HymnIssue.Text && !isInternalUser) || error !== undefined;

  return (
    <>
      <PdfDoc key='pdf'>
        <BackButton onClick={onClose}><BackArrow/></BackButton>
        <div>
          {
            isRequestPageVisible &&
            <RequestSongPage
              issue={error !== undefined ? HymnIssue.Missing : HymnIssue.Text}
              hymnalName={ensureExists(hymn.hymnal)}
              songNumber={hymn.number}/>
          }
          <div style={{...((numPages === undefined || error || isRequestPageVisible) && {visibility: 'hidden'})}}>
            <Document
              file={pdfPath}
              options={{workerSrc: "/pdf.worker.js"}}
              onLoadSuccess={onLoadSuccess}
              onLoadError={onLoadError}
            >
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(page => <Page pageNumber={page} key={page} width={windowDimensions.width}/>)}
            </Document>
          </div>
        </div>
      </PdfDoc>
      {isPDFVisible && !isRequestPageVisible && error === undefined && numPages === undefined && renderLoadingPage()}
    </>
  );
}

const PdfDoc = styled.div`
  overflow-y: scroll;
  overflow-x: clip;
  padding-bottom: 30px;
  transform: translateY(-60px);
`

const BackButton = styled.div`
  position: sticky;
  top: 20px;
  width: 32px;
  z-index:1000;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 8px;
  padding: 5px 10px;
  border-radius: 3px;
`

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingMessage = styled.div`
  font-size: 1.3em;
  width: 20ch;
  padding-bottom: 1.5em;
`;
