import {ensureUnreachable} from '../../common/util';
import {Demo} from './onboarding/demo';
import {Questions} from './onboarding/questions';
import {Start} from './onboarding/start';
import {HouseholdFind} from './onboarding/household_find';
import {HouseholdWelcome} from './onboarding/household_welcome';
import {HouseholdStart} from './onboarding/household_start';
import {HouseholdLastName} from './onboarding/household_last_name';
import {HouseholdEmail} from './onboarding/household_email';
import {HouseholdNotFound} from './onboarding/household_not_found';
import {HouseholdJoin} from './onboarding/household_join';
import {HouseholdCreate} from './onboarding/household_create';
import {ChurchChoice} from './onboarding/church_choice';
import {ChurchWelcome} from './onboarding/church_welcome';
import {AllowNotifications} from './onboarding/allow_notifications';
import {Awesome} from './onboarding/awesome';
import {SurveyConfidenceLevel} from './onboarding/survey_confidence_level';
import {SurveyDiscovery} from './onboarding/survey_discovery';
import {SurveyExpectedUsage} from './onboarding/survey_expected_usage';
import {ShareMusicLeader} from './onboarding/share_music_leader';
import {ShareFriendsFamily} from './onboarding/share_friends_family';
import {SurveyTimeInvestment} from './onboarding/survey_time_investment';
import {SurveyHouseholdSchedule} from './onboarding/survey_household_schedule';
import {SubscribeStart} from './onboarding/subscribe_start';
import {SubscribePayment} from './onboarding/subscribe_payment';
import {Download} from './onboarding/download';
import {SubscribeThankYou} from './onboarding/subscribe_thank_you';
import {OnboardingPages} from '../../common/pages';
import {SubscribeFindHousehold} from './onboarding/subscribe_find_household';
import {useLocation} from 'react-router-dom';
import {Location} from 'history';
import {PageTransition} from '@steveeeie/react-page-transition'; // TODO(hewitt): switch to Framer Motion
import {AppContext, AppContextProvider} from './onboarding/app_context';
import React, {useCallback, useContext, useState} from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as BackArrowIcon} from '../assets/arrow-previous-left-icon.svg';
import {ReactComponent as CloseIcon} from '../assets/close.svg';
import {navigateBack} from './onboarding/frame';
import {ProgressBar} from './onboarding/progress_bar';
import {InAppOnboardingAnnouncement} from './onboarding/in_app_onboarding_announcement';
import {CustomMusicUploadSongName} from './onboarding/custom_music_upload_song_name';
import {CustomMusicUpload} from './onboarding/custom_music_upload';
import {CustomMusicUploadPsalmNumber} from './onboarding/custom_music_upload_psalm_number';
import {InAppAdventNotification} from './onboarding/in_app_advent_notification';

export const OnboardingPage = () => {
  return (
    <AppContextProvider>
      <PageTransitionWrapper />
    </AppContextProvider>
  );
};

const PageTransitionWrapper = () => {
  const location = useLocation();
  const page = useOnboardingPage(location);
  const appContext = useContext(AppContext);
  const [followupTimer, setFollowupTimer] = useState<ReturnType<typeof setTimeout> | undefined>()
  const onBackCallback = useCallback(() => {
    if (followupTimer) {
      clearTimeout(followupTimer);
    }
    navigateBack(appContext, appContext?.onBack);

    // wait for the page transition to be done before reversing the transition direction
    // note that using setTimeout in the Button's onClick handler fixes this but breaks iOS input focus
    setFollowupTimer(setTimeout(() => appContext?.setPageAnimation('moveToLeftFromRight'), 700));
  }, [appContext, followupTimer, setFollowupTimer]);

  if (!appContext) {
    return null;
  }

  const isFirefoxBrowser = window.navigator.userAgent.includes('Firefox');

  const pageTransition = (
    <PageTransition
      preset={appContext.pageAnimation}
      transitionKey={location.pathname + location.hash + location.search}
      enterAnimation=''
      exitAnimation=''
    >
      {renderOnboardingPage(page)}
    </PageTransition>
  );

  return (
    <>
      {!appContext.suppressBackButton && (
        <BackButton key='back' onClick={onBackCallback}>
          <LargeBackArrow/>
        </BackButton>
      )}
      <ProgressBar page={page}/>
      {appContext.onClose && (
        <CloseButton key='close' onClick={appContext.onClose}>
          <CloseIcon />
        </CloseButton>
      )}
      {
        // TODO(hewitt): Remove this hack when we switch to Framer Motion (test in FireFox)
        // FireFox has a bug that collapses the page height
        isFirefoxBrowser
          ? (
            <div style={{height: '100vh'}}>
              {pageTransition}
            </div>
          ) : pageTransition
      }
    </>
  );
};

function useOnboardingPage(location: Location): OnboardingPages {
  const pageName = location.hash && location.hash.slice(1);
  if (pageName && Object.values(OnboardingPages).includes(pageName as OnboardingPages)) {
    return pageName as OnboardingPages;
  }
  return OnboardingPages.Demo;
}

function renderOnboardingPage(page: OnboardingPages) {
  switch (page) {
    case OnboardingPages.AllowNotifications:
      return <AllowNotifications />;
    case OnboardingPages.Awesome:
      return <Awesome/>;
    case OnboardingPages.ChurchChoice:
      return <ChurchChoice/>;
    case OnboardingPages.ChurchWelcome:
      return <ChurchWelcome/>;
    case OnboardingPages.CustomMusicUploadSongName:
      return <CustomMusicUploadSongName/>
    case OnboardingPages.CustomMusicUploadPsalmNumber:
      return <CustomMusicUploadPsalmNumber/>
    case OnboardingPages.CustomMusicUpload:
      return <CustomMusicUpload/>;
    case OnboardingPages.SurveyConfidenceLevel:
      return <SurveyConfidenceLevel/>;
    case OnboardingPages.Demo:
      return <Demo/>;
    case OnboardingPages.SurveyDiscovery:
      return <SurveyDiscovery/>;
    case OnboardingPages.SurveyHouseholdSchedule:
      return <SurveyHouseholdSchedule/>;
    case OnboardingPages.SurveyExpectedUsage:
      return <SurveyExpectedUsage/>;
    case OnboardingPages.SurveyTimeInvestment:
      return <SurveyTimeInvestment/>;
    case OnboardingPages.InAppAnnouncement:
      return <InAppOnboardingAnnouncement/>
    case OnboardingPages.InAppAdventNotification:
      return <InAppAdventNotification/>;
    case OnboardingPages.HouseholdStart:
      return <HouseholdStart/>;
    case OnboardingPages.HouseholdLastName:
      return <HouseholdLastName/>
    case OnboardingPages.HouseholdEmail:
      return <HouseholdEmail/>;
    case OnboardingPages.HouseholdNotFound:
      return <HouseholdNotFound/>;
    case OnboardingPages.HouseholdCreate:
      return <HouseholdCreate/>;
    case OnboardingPages.HouseholdJoin:
      return <HouseholdJoin/>;
    case OnboardingPages.HouseholdFind:
      return <HouseholdFind/>;
    case OnboardingPages.HouseholdWelcome:
      return <HouseholdWelcome/>;
    case OnboardingPages.Questions:
      return <Questions/>;
    case OnboardingPages.ShareFriendsFamily:
      return <ShareFriendsFamily/>;
    case OnboardingPages.ShareMusicLeader:
      return <ShareMusicLeader/>;
    case OnboardingPages.Start:
      return <Start/>;
    case OnboardingPages.SubscribeStart:
      return <SubscribeStart/>;
    case OnboardingPages.SubscribeFindHousehold:
      return <SubscribeFindHousehold/>;
    case OnboardingPages.SubscribePayment:
      return <SubscribePayment/>;
    case OnboardingPages.SubscribeThankYou:
      return <SubscribeThankYou/>;
    case OnboardingPages.Download:
      return <Download/>;
    default:
      ensureUnreachable(page);
  }
}

const CloseButton = styled.button`
  z-index: 1;                      // TODO(hewitt): should not be necessary - wasn't when in frame.ts
  fill: var(--color-text-light);
  width: 50px;
  position: absolute;
  top: 15px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const BackButton = styled.button`
  z-index: 1;                      // TODO(hewitt): should not be necessary - wasn't when in frame.ts
  position: absolute;
  top: 18px;
  left: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const LargeBackArrow = styled(BackArrowIcon)`
  fill: var(--color-text-light);
  height: 30px;
`;
