import React, {useCallback, useEffect, useState} from 'react';
import {getHymnFromSongListEntry, HymnsList} from './hymns_list';
import '../shared.css';
import {specialCaseSchools, synchronizeWeeklySongLists, useChurch} from '../data/use_church';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {Church, OrganizationType, SongList, SongListDeprecated, SongListType} from '../../common/model';
import {dateFromString, DateString, thisSundayDate} from '../../common/date_string';
import {useHousehold} from '../data/use_household';
import {ensureUnreachable} from '../../common/util';
import {Pages} from '../../common/pages';
import {getUrlForPage} from '../util/path';
import {shareWithMusicLeader} from './onboarding/common';
import {DemoChurch, synchronizeDemoSongList} from '../data/demo_song_list';
import {stringFromChurch} from '../../common/server_api';
import {Link, useNavigate} from 'react-router-dom';
import {HymnalWithHymns, useHymnals} from '../data/use_hymnals';
import {getHymnOfTheMonthTitle, getSundayTitle} from '../../common/church';
import {Hymn} from '../sequencer';
import {CustomMusic, useCustomMusic} from '../data/use_custom_music';

const thisWeekHymnalSlug = 'THIS-WEEK';

function getHymnalsFromSongLists({songLists, church, allHymnals, customMusic}: {
  songLists: Array<SongList | SongListDeprecated>;
  church?: Church;
  allHymnals: HymnalWithHymns[] | undefined;
  customMusic: CustomMusic | undefined;
}): HymnalWithHymns[] {
  const filteredSongLists = songLists
    .filter(songList =>
      songList.type !== SongListType.StaticList || (
        songLists.length === 1 &&
        church?.type !== OrganizationType.Church
      ));
  const orderedSongLists = [
    ...filteredSongLists.filter(songList => songList.type === SongListType.HymnOfTheMonth),
    ...filteredSongLists
      .filter(songList => songList.type === SongListType.WorshipService)
      .sort((lhs, rhs) => rhs.date.localeCompare(lhs.date)),
    ...filteredSongLists.filter(songList => songList.type === SongListType.Event),
  ];
  const hymnals: HymnalWithHymns[] = orderedSongLists
    .map((songList) => {
      const hymns = songList.songs
        .map(song => getHymnFromSongListEntry({song, hymnals: allHymnals, customMusic}))
        .filter(hymn => hymn) as Hymn[];
      return {
        id: 0,
        name: getSongListName(songList, hymns, songLists.length),
        hymns,
        slug: thisWeekHymnalSlug,
      };
    });
  if (
    !songLists.map(({date}) => date).includes(thisSundayDate()) &&
    church?.type === OrganizationType.Church &&
    !specialCaseSchools.includes(church?.name) &&
    // hide empty "This Sunday" for single static song list as well as demo church
    (songLists.length > 1 || hymnals.length === 0 || hymnals[0].name !== '') &&
    stringFromChurch(church) !== DemoChurch
  ) {
    // keep hymn of the month first
    const containsHymnOfTheMonth = songLists.length > 0 && songLists[0].type === SongListType.HymnOfTheMonth;
    const start = containsHymnOfTheMonth ? 1 : 0;
    hymnals.splice(start, 0, {
      id: 0,
      name: getSundayTitle(thisSundayDate()),
      hymns: [],
      message: <div>Your music leader has not yet added any songs for this week.</div>,
      slug: thisWeekHymnalSlug,
    })
  }
  return hymnals;
}

function getSongListName(songList: SongList | SongListDeprecated, hymns: Hymn[], songListCount: number) {
  const {name, type, date: dateStr, time} = songList;
  switch (type) {
    case SongListType.HymnOfTheMonth:
      return getHymnOfTheMonthTitle(hymns);
    case SongListType.WorshipService:
      return getSundayTitle(dateStr);
    case SongListType.Event:
      const date = dateFromString(dateStr);
      const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
      const shortTime = getTimeAsShortString(time);
      const timePart = shortTime ? ` @ ${shortTime}` : '';
      return `${name}${datePart}${timePart}`;
    case SongListType.StaticList:
      // If there is only one static song list, it gets an empty title (flat list of songs)
      return songListCount === 1 ? '' : 'Songs';
    default:
      ensureUnreachable(type);
  }
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

function getTimeAsShortString(time?: string): string | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/T?(?<hour>\d\d):(?<minute>\d\d)(:(?<second>\d\d))?/);
  if (!match || !match.groups) {
    return;
  }
  const {hour: hourString, minute} = match.groups;
  const militaryHour = Number(hourString);
  const hour = militaryHour === 0 ? 12 : (militaryHour > 12 ? militaryHour - 12 : militaryHour);
  const ampm = militaryHour < 12 || militaryHour === 24 ? 'am' : 'pm';
  if (minute === '00') {
    return `${hour}${ampm}`;
  }
  return `${hour}:${minute}${ampm}`;
}

export interface Props {
  isDemo?: boolean;
  checkSongLists?: (songLists: Array<{type: SongListType, date: DateString}> | undefined) => void;
  showThisSundayOnly?: boolean;
}

export const OrganizationPage = ({isDemo, checkSongLists, showThisSundayOnly}: Props) => {
  const {church} = useChurch();
  const {household} = useHousehold();
  const [songsForWeek] = useLocalStorage(LocalStorageKey.WeeklySongList);
  const [demoSongs] = useLocalStorage(LocalStorageKey.DemoSongList);
  const allHymnals = useHymnals();
  const customMusic = useCustomMusic();
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isDemo) {
      void synchronizeDemoSongList();
    } else {
      void synchronizeWeeklySongLists();
    }
  }, [isDemo]);

  useEffect(() => checkSongLists?.(songsForWeek), [checkSongLists, songsForWeek]);

  useEffect(() => {
    let songLists: Array<SongList | SongListDeprecated>;
    if (isDemo) {
      songLists = demoSongs;
    } else if (showThisSundayOnly) {
      songLists = songsForWeek?.filter(songList =>
        songList.type === SongListType.WorshipService && songList.date === thisSundayDate()
      );
    } else {
      songLists = songsForWeek;
    }
    setHymnals(getHymnalsFromSongLists({songLists, church: isDemo ? undefined : church, allHymnals, customMusic}));
  }, [allHymnals, customMusic, isDemo, demoSongs, showThisSundayOnly, songsForWeek, church]);

  const onShare = useCallback(async () => {
    await shareWithMusicLeader();
  }, []);

  const askYourMusicLeader =
    <button className="inlineLink" onClick={onShare}>
      ask your music leader
    </button>;

  let message: JSX.Element | undefined;
  if (isDemo) {
    message = undefined;
  } else if (!household) {
    // this code path should be impossible (RootPage should have navigated to onboarding demo, passing isDemo=true)
    // displaying URL (window.location.href) as a debugging tool because some customers are still seeing it... :(
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span
        className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>sign in</span> if your church
        already participates.<br/>
        <br/>
        <p>{window.location.href}</p>
      </div>;
  } else if (!church) {
    message = <div>To use this tab, select a church in household <Link to={getUrlForPage(Pages.Settings)}>settings</Link> or {askYourMusicLeader} to add your church to the app. If you are the music leader, <Link to='https://calendar.app.google/gnnXn7zGBiB1fN469' target="_blank">click here</Link> to talk about getting your church setup.</div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0) {
    message = <div>Your music director has not yet added songs for this Sunday.</div>;
  }
  return (
    <HymnsList
      title={church?.name ?? 'Your Church'}
      weeklySongGroups={hymnals}
      message={message}
      isDemo={isDemo}
      showThisSundayOnly={showThisSundayOnly}
    />
  );
};
