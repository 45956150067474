import {Header} from '../header';
import {OuterPageContent} from '../../shared';
import {Footer} from '../footer';
import styled from 'styled-components';
import {SingingPlanTile} from './singing_plan_tile';
import {ThisSundayTile} from './this_sunday_tile';
import {getMonthName} from '../../../common/date_string';

export const TodayPage = () => {
  // TODO(hewitt): display Christian holiday name if appropriate (e.g. "Christmas Day 2024 AD", "Easter Sunday 2024 AD")
  const date = new Date();
  const title = `${getMonthName(date)} ${date.getDate()}, ${date.getFullYear()} AD`;
  return (
    <OuterPageContent>
      <Header title={title}/>
      <TileBackground>
        <SingingPlanTile/>
        <ThisSundayTile/>
      </TileBackground>
      <Footer/>
    </OuterPageContent>
  );
}

const TileBackground = styled.div`
  font-size: min(max(4.5vw, 0.9em), 1.5em);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--color-background-search);
  text-align: left;
`;
