import {clearClientLocalStorage} from "../data/client_local_storage";
import * as server_api from "../../common/server_api";

export function clearRetryCountAfterSuccessfulLoad() {
  void setTimeout(clearRetryCount, 1000);
}

let allowExceptionsOnLocalHost = true;

//
// Crash Handling:
// - First crash - report the crash and reload the page with hash &retries=1
// - Second crash - clear the local storage & service worker cache and reload with url '/' + hash &retries=2
// - Third crash - give up and show the FailurePage
// - After a successful page load (5 seconds), clear retry count
//   - Includes failure page to allow manual refresh to start over on retry count
export async function handleCrash(error: any): Promise<void> {
  const {hostname} = window.location;
  const isLocalHost = hostname.includes('localhost');
  if (allowExceptionsOnLocalHost && isLocalHost) {
    throw error;
  }
  const retries = getRetryCountFromHash();
  if (!retries) {
    // first crash - just reload & see if that fixes it
    setRetryCount(1);
    window.location.reload();
  } else if (retries === 1) {
    // failed reload once - blow away all local storage and try again
    clearClientLocalStorage();
    await clearServiceWorkerCache();
    setRetryCount(2);
    window.location.href = '/' + window.location.hash;
    window.location.reload();
  } else {
    clearRetryCount();
  }
}

async function clearServiceWorkerCache() {
  const keys = await caches.keys();
  for (const key of keys) {
    await caches.delete(key);
  }
}

export function reportCrash(event: ErrorEvent) {
  // avoid reporting twice for same error
  // "reported" is a made up property - this works because the same error object is used the second time around
  if (event.error.reported) {
    return false;
  }
  event.error.reported = true;

  console.log(`Reporting error ${event.error.stack}`);
  void server_api.addIssue({
    timestamp: Date.now(),
    description: {
      tag: 'crash',
      description: event.error.stack,
    },
  });
  return false;
}

function getRetryCountFromHash() {
  const retries = window.location.hash.match(/retries=(?<retries>\d+)/)?.groups?.retries;
  return retries ? Number(retries) : undefined;
}

function setRetryCount(retries: number) {
  clearRetryCount();
  const hashValue = `&retries=${retries}`;
  window.location.hash = window.location.hash ? window.location.hash + hashValue : '#' + hashValue;
}

function clearRetryCount() {
  const newHash = window.location.hash.replace(/&retries=\d+/i, '');
  if (newHash === '' || newHash === '#') {
    window.history.replaceState(null, 'ignore', ' ');
  } else {
    window.location.hash = newHash;
  }
}
