import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {useLocation, useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import * as server_api from '../../common/server_api';
import styled from 'styled-components/macro';
import {
  getAppStoreUrl,
  isInsideMobileBrowser
} from '../util/billing';
import {synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {OnboardingPages} from '../../common/pages';
import {
  ChurchIdTokenQueryString,
  clearOnboardingLocalStorage,
  isDebugOnboardingEnvironment,
  Message, navigateAfterInvite,
  navigateToOnboardingPage
} from './onboarding/common';
import {Frame} from './onboarding/frame';
import {Button} from './onboarding/button';
import {synchronizeChurchWithServer} from '../data/use_church';

export function HouseholdInvite() {
  const {search} = useLocation();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const churchId = new URLSearchParams(search).get(ChurchIdTokenQueryString);
  const [finished, setFinished] = useState(false);
  const [church] = useLocalStorage(LocalStorageKey.Church);
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (householdToken && churchId) {
        await server_api.setHouseholdChurch(householdToken, {id: Number(churchId)});
        await synchronizeChurchWithServer();
        await synchronizeHouseholdWithServer();
        clearOnboardingLocalStorage();
        setFinished(true);
      }
    })();
  }, [householdToken, churchId]);

  const onContinue = useCallback(() => {
    navigateAfterInvite(navigate, household);
  }, [household, navigate]);

  useEffect(() => {
    if (isInsideMobileBrowser() && !isDebugOnboardingEnvironment()) {
      const appStoreUrl = getAppStoreUrl();
      if (appStoreUrl) {
        window.location.href = appStoreUrl;
        return;
      }
    }

    if (!householdToken) {
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdStart);
    }
  }, [householdToken, navigate]);

  // @ts-ignore
  if (!churchId || isNaN(churchId)) {
    const href = 'mailto:support@crescendosw.com?subject=Missing church id for household invite';
    return (
      <Frame
        onboardingPage={undefined}
        mainContent={(
          <Message $widthInChars={20}>
            An internal error has occurred. <MailLink href={href}>Click here</MailLink> to reach out to support.
          </Message>
        )}
        footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
        onEnterKeypress={onContinue}
      />
    );
  }

  if (!finished || !church) {
    return (
      <Frame
        onboardingPage={undefined}
        mainContent={<Message $widthInChars={24}>Updating household church...</Message>}
        footerContent={<div/>}
      />
    );
  }

  return (
    <Frame
      onboardingPage={undefined}
      mainContent={(
        <Message $widthInChars={24}>
          Congratulations!<br/>
          <br/>
          Your household is associated with<br/>
          {church.name}.
        </Message>
      )}
      footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
      onEnterKeypress={onContinue}
      suppressBackButton={true}
    />
  );
}

const MailLink = styled.a`
  color: blue;
  text-decoration: underline;
`;
