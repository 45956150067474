import React, {useCallback} from 'react';
import {Message, navigateToOnboardingPage} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

export const Start = () => {
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.Questions);
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.Start}
      mainContent={(
        <Message $widthInChars={16}>Hi! Thanks for checking us out.</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
