import './footer.css';
import {ReactComponent as SettingsIcon} from "../assets/settings-outlined.svg";
import {ReactComponent as LibraryBooks} from '../assets/library-books.svg';
import {ReactComponent as CalendarIcon} from "../assets/calendar-outlined.svg";
import {ReactComponent as ChurchIcon} from "../assets/church.svg";
import {ReactComponent as SchoolIcon} from "../assets/school.svg";
import {ReactComponent as SmallGroupIcon} from "../assets/small-group.svg";
import {ReactComponent as OutlinedHeartIcon} from '../assets/heart-outline.svg';
import {isDesktop} from 'react-device-detect';
import {getPageFromLocation, getUrlForPage} from "../util/path";
import {isWKWebView} from "../authentication/apple_login";
import {Pages} from '../../common/pages';
import {Link, useLocation} from 'react-router-dom';
import {useChurch} from '../data/use_church';
import {getNameForOrgType, OrganizationType} from '../../common/model';
import {ensureUnreachable} from '../../common/util';

export const Footer = () => {
  const location = useLocation();
  const page = getPageFromLocation(location) ?? Pages.ThisWeek;
  const {church} = useChurch();

  const ThisWeekIconClass = getIconForOrgType(church?.type);

  function getIconClassName(forPage: Pages) {
    let sizeClassName: string;
    switch (forPage) {
      case Pages.Today:
        sizeClassName = 'thisWeekIcon';
        break;
      case Pages.ThisWeek: // organization page (church / school / small group)
        sizeClassName = 'organizationIcon'; // TODO(hewitt): should be church / school / group icon
        break;
      case Pages.Library:
        sizeClassName = 'libraryIcon' + (forPage === page ? ' selectedLibraryIcon' : ' unselectedLibraryIcon');
        break;
      case Pages.Settings:
        sizeClassName = 'settingsIcon';
        break;
      case Pages.Favorites:
        sizeClassName = 'favoritesIcon';
        break;
      case Pages.Help:
        sizeClassName = 'helpIcon';
        break;
      default:
        sizeClassName = '';
        break;
    }

    if (forPage === Pages.ThisWeek && ThisWeekIconClass === ChurchIcon) {
      sizeClassName += ' churchIcon';
    }

    return sizeClassName + ' footerIcon ' + (forPage === page ? 'selectedIcon' : 'unselectedIcon');
  }

  function getLabelClassName(forPage: Pages) {
    return forPage === page ? 'selectedLabel iconLabel' : 'unselectedLabel iconLabel';
  }

  // favorites moves before library if household singing plans are enabled
  const favorites = (
    <Link key='favorites' className="iconDiv" to={getUrlForPage(Pages.Favorites)}>
      <OutlinedHeartIcon className={getIconClassName(Pages.Favorites)}/>
      <div className={getLabelClassName(Pages.Favorites)}>Favorites</div>
    </Link>
  );

  // TODO(hewitt): Should be using <NavLink> to style currently active tab
  return (
    <div key='footer' className='footer' style={isWKWebView() || isDesktop? {paddingBottom: 0} : {paddingBottom: 20}}>
      <Link key='today' className="iconDiv" to={getUrlForPage(Pages.Today)}>
        <CalendarIcon className={getIconClassName(Pages.Today)} />
        <div className={getLabelClassName(Pages.Today)}>Today</div>
      </Link>
      <Link key='this-week' className="iconDiv" to={getUrlForPage(Pages.ThisWeek)}>
        <ThisWeekIconClass className={getIconClassName(Pages.ThisWeek)} />
        <div className={getLabelClassName(Pages.ThisWeek)}>
          {getNameForOrgType(church?.type)}
        </div>
      </Link>
      {favorites}
      <Link key='library' className="iconDiv" to={getUrlForPage(Pages.Library)}>
        <LibraryBooks className={getIconClassName(Pages.Library)}/>
        <div className={getLabelClassName(Pages.Library)}>Library</div>
      </Link>
      <Link key='settings' className="iconDiv" to={getUrlForPage(Pages.Settings)}>
        <SettingsIcon className={getIconClassName(Pages.Settings)}/>
        <div className={getLabelClassName(Pages.Settings)}>Settings</div>
      </Link>
    </div>
  )
};

export function getIconForOrgType(type?: OrganizationType): any {
  if (!type) {
    return ChurchIcon;
  }
  switch (type) {
    case OrganizationType.School:
      return SchoolIcon;
    case OrganizationType.Church:
      return ChurchIcon;
    case OrganizationType.SmallGroup:
      return SmallGroupIcon;
    default:
      ensureUnreachable(type);
  }
}
