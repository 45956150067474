import {HymnalSlug, HymnalsManifest, SongNumber, SongSlug} from './model';
import {userVisibleSongNumber} from './util';

export const musicManifestFilename = 'manifest3.json';
export const ignoreDirectories = ['.git', '.idea'];
export const metadataFilename = 'metadata.json';

export const customMusicDir = 'custom-music';
export const hymnalsDir = 'hymnals';
export const hymnalsPDFDir = 'hymnals';
export const vocalsDir = 'vocals';
export const thisWeekDirName = 'this_week';

export function getSongSlugForHymn(
  hymnalsManifest: HymnalsManifest | undefined,
  hymn: {number: number, hymnal: string, basePath?: string}
): string | undefined {
  const songNumber = userVisibleSongNumber(hymn.number);
  let hymnalSlug: HymnalSlug | undefined;
  let churchId: string | undefined;
  if (hymn.basePath) {
    churchId = hymn.hymnal.match(/^(?<churchId>\d+)/)?.groups?.churchId;
    if (!churchId) {
      console.log(`ERROR: Cannot parse church Id from hymnal "${hymn.hymnal}" for hymn with basePath ${hymn.basePath}`);
      return undefined;
    }
  } else {
    hymnalSlug = hymnalsManifest?.[hymn.hymnal]?.slug;
    if (!hymnalSlug) {
      return undefined;
    }
  }
  return getSongSlug({songNumber, hymnalSlug, churchId: churchId ? Number(churchId) : undefined});
}

export function getSongSlug({songNumber, hymnalSlug, churchId}: {
  songNumber: SongNumber,
  hymnalSlug?: HymnalSlug,
  churchId?: number
}): SongSlug {
  if (!hymnalSlug && !churchId) {
    throw new Error('Must specify either hymnalSlug or songNumber');
  }
  const prefix = hymnalSlug ? `hymnal/${hymnalSlug.toLowerCase()}` : `church/${churchId}`;
  return `${prefix}/song/${songNumber}`;
}
