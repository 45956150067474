import * as ReactDOM from "react-dom/client";
import * as React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import {ensureExists} from "./common/util";
import {initializeApp} from './client/util/startup';
import {OrganizationPage} from './client/pages/this_week_page';
import {Root} from './client/pages/root_page';
import {OnboardingPage} from './client/pages/onboarding_page';
import {getOnboardingPath, OnboardingPages, Pages} from './common/pages';
import {FailurePage} from './client/pages/failure_page';
import {FavoritesPage} from './client/pages/favorites_page';
import {HelpPage} from './client/pages/help_page';
import {LibraryPage} from './client/pages/library_page';
import {LoadingPage} from './client/pages/loading';
import {SettingsPage} from './client/pages/settings_page';
import {AdminInvite} from './client/pages/admin_invite';
import {HouseholdInvite} from './client/pages/household_invite';
import {HouseholdCreationPage} from './client/pages/household_creation_page';
import {PrivacyPage} from './client/pages/privacy_page';
import {TodayPage} from './client/pages/today/today_page';

// TODO(hewitt): Move into src/client -> see note in webpack.config.js

// provide an answer to https://stackoverflow.com/questions/70341850/react-redirect-to-login-page-when-not-authenticated
// good discussion: https://github.com/remix-run/react-router/issues/10637
// good search: https://www.google.com/search?q=%2BcreateBrowserRouter+hide+routes+behind+login+page
// (searches must include "createBrowserRouter")
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <FailurePage/>,
    children: [
      {
        index: true,
        element: <OrganizationPage/>,
      },
      {
        path: Pages.AdminInvite,
        element: <AdminInvite/>,
      },
      {
        path: Pages.Demo,
        element: <Navigate to={getOnboardingPath(OnboardingPages.Demo)}/>,
      },
      {
        path: Pages.Favorites,
        element: <FavoritesPage/>,
      },
      {
        path: Pages.Help,
        element: <HelpPage/>,
      },
      {
        path: Pages.HouseholdCreation,
        element: <HouseholdCreationPage/>,
      },
      {
        path: Pages.HouseholdInvite,
        element: <HouseholdInvite/>,
      },
      {
        path: Pages.Library,
        element: <LibraryPage/>,
      },
      {
        path: Pages.Loading,
        element: <LoadingPage/>,
      },
      {
        path: Pages.Onboarding,
        element: <OnboardingPage/>,
      },
      {
        path: Pages.Privacy,
        element: <PrivacyPage/>,
      },
      {
        path: Pages.Settings,
        element: <SettingsPage/>,
      },
      {
        path: Pages.ThisWeek,
        element: <OrganizationPage/>,
      },
      {
        path: Pages.Today,
        element: <TodayPage/>,
      },
    ],
  },
]);

initializeApp(router.navigate);

const rootElement = ensureExists(document.getElementById("root"));
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
