import {useHousehold} from './use_household';
import {WeeklyHouseholdSingingPlan} from '../../common/model';
import {useCallback, useEffect} from 'react';
import * as server_api from "../../common/server_api"
import {DateString} from '../../common/date_string';
import {localStorageGet, LocalStorageKey, localStorageSet} from './client_local_storage';
import {useLocalStorage} from './use_local_storage';

export function useHouseholdSingingPlan(): {
  singingPlan: WeeklyHouseholdSingingPlan | undefined;
  markSingingPlanComplete: (date: DateString) => void;
} {
  const {household} = useHousehold();
  const [singingPlan] = useLocalStorage(LocalStorageKey.HouseholdSingingPlan);

  useEffect(() => {
    void synchronizeHouseholdSingingPlan();
  }, [household]);

  const markSingingPlanCompleted = useCallback((date: DateString) => {
    void (async () => {
      if (!household?.token) {
        return;
      }
      await server_api.markSingingPlanCompleted({householdToken: household.token, date});
      void synchronizeHouseholdSingingPlan();
    })();
  }, [household?.token]);

  return {singingPlan, markSingingPlanComplete: markSingingPlanCompleted};
}

export async function synchronizeHouseholdSingingPlan() {
  const household = localStorageGet(LocalStorageKey.Household);
  if (!household?.token) {
    return;
  }
  const currentPlan = localStorageGet(LocalStorageKey.HouseholdSingingPlan);
  const serverSingingPlan = await server_api.getHouseholdSingingPlan({householdToken: household.token});
  if (JSON.stringify(serverSingingPlan) === JSON.stringify(currentPlan)) {
    return;
  }
  localStorageSet(LocalStorageKey.HouseholdSingingPlan, serverSingingPlan);
}
