import React, {useCallback} from 'react';
import {Frame} from './frame';
import {
  Message,
  navigateToOnboardingPage, setOnboardingHouseholdSurveyResponse,
  shareWithFriendsAndFamily
} from './common';
import {Button} from './button';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

export const ShareFriendsFamily = () => {
  const navigate = useNavigate();
  const onShare = useCallback(async () => {
    setOnboardingHouseholdSurveyResponse({sharedWithFamily: true});
    await shareWithFriendsAndFamily();
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdWelcome);
  }, [navigate]);
  const onSkip = useCallback(() => {
    setOnboardingHouseholdSurveyResponse({sharedWithFamily: false});
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdWelcome);
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.ShareFriendsFamily}
      mainContent={(
        <Message $widthInChars={18}>Invite your friends & family to join!</Message>
      )}
      footerContent={(
        <>
          <Button onClick={onShare}>SHARE</Button>
          <Button onClick={onSkip}>MAYBE LATER</Button>
        </>
      )}
      onEnterKeypress={onShare}
    />
  );
};
