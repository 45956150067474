import React, {useCallback} from 'react';
import {BoldMessage, isAdminInvite, Message, navigateToOnboardingPage} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

export const Questions = () => {
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SurveyDiscovery);
  }, [navigate]);
  const message = isAdminInvite()
    ? <Message $widthInChars={22}>Here are a few quick questions that we ask your community.</Message>
    : <Message $widthInChars={20}>Just <BoldMessage>a few quick questions</BoldMessage> before we get started!</Message>;
  return (
    <Frame
      onboardingPage={OnboardingPages.Questions}
      mainContent={message}
      footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
      onEnterKeypress={onContinue}
    />
  );
};
