import React, {useCallback} from 'react';
import {BoldMessage, Message, navigateToOnboardingPage} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {OnboardingPages} from '../../../common/pages';
import {useNavigate} from 'react-router-dom';

export const HouseholdNotFound = () => {
  const [householdEmail] = useLocalStorage(LocalStorageKey.OnboardingHouseholdEmail);
  const [lastName] = useLocalStorage(LocalStorageKey.OnboardingLastName);
  const navigate = useNavigate();
  const onChangeEmail = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdFind);
  }, [navigate]);
  const onNewHousehold = useCallback(() => {
    navigateToOnboardingPage(
      navigate,
      lastName
        ? OnboardingPages.HouseholdCreate
        : OnboardingPages.HouseholdLastName
    );
  }, [lastName, navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdNotFound}
      mainContent={(
        <Message $widthInChars={26}>
          We could not find a household with email <BoldMessage>{householdEmail}</BoldMessage>.<br/><br/>
          Would you like try another email?
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onChangeEmail}>TRY AGAIN</Button>
          <Button onClick={onNewHousehold}>NEW HOUSEHOLD</Button>
        </>
      )}
      onEnterKeypress={onChangeEmail}
    />
  );
};
