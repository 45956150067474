import React from 'react';
import {Outlet} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components/macro';
import {useSetupIOSLogin} from "../authentication/apple_login";
import useWindowDimensions from '../util/useWindowDimensions';
import {useDisplayInAppOnboardingNotification} from './onboarding/in_app_onboarding_announcement';
import {useDisplayDemoPage} from './onboarding/use_display_demo_page';
import {useDisplayInAppAdventNotification} from './onboarding/in_app_advent_notification';

export const Root = () => {
  const windowDimensions = useWindowDimensions();

  // hooks that navigate to other pages under certain conditions
  useDisplayDemoPage();
  useDisplayInAppOnboardingNotification();
  useDisplayInAppAdventNotification();

  useSetupIOSLogin();
  return (
    <>
      <GlobalStyles $windowHeight={windowDimensions.height} $windowWidth={windowDimensions.width}/>
      <Outlet/>
    </>
  );
}

const GlobalStyles = createGlobalStyle<{$windowHeight: number, $windowWidth: number}>`
  * {
    --view-height: ${props => props.$windowHeight}px;
    --view-width: ${props => props.$windowWidth}px;
  }
`;
