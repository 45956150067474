import {dateStringFromDate, lastSundayDate, nextSundayDate, thisSundayDate} from './date_string';

const todayTitle = 'Today';
const thisSundayTitle = 'This Sunday';
const lastSundayTitle = 'Last Sunday';
const nextSundayTitle = 'Next Sunday';

export function getHymnOfTheMonthTitle(hymns: Array<{psalm?: string, title?: string}>) {
  const isPsalm = hymns.length > 0 && (hymns[0].psalm || hymns[0].title?.toLowerCase().includes('psalm'));
  const prefix = isPsalm ? 'Psalm' : 'Hymn';
  return `${prefix} of the Month`;
}

export function getSundayTitle(date: string) {
  const today = dateStringFromDate(new Date());
  switch (date) {
    case today:
      return todayTitle;
    case thisSundayDate():
      return thisSundayTitle;
    case lastSundayDate():
      return lastSundayTitle;
    case nextSundayDate():
      return nextSundayTitle;
    default:
      return date;
  }
}
