import {Hymn} from "../sequencer";
import {Location} from 'history';
import {ensureExists, getNumericSongNumber} from "../../common/util";
import {Pages} from '../../common/pages';
import {SongSlug} from '../../common/model';
import {HymnalWithHymns} from '../data/use_hymnals';
import {CustomMusic} from '../data/use_custom_music';

export function generateFileName(hymn: Hymn) {
  let titleAllCaps = ensureExists(hymn.title).toUpperCase();
  const match = hymn.number.toString().match(/^(?<wholeNumber>\d+)(\.(?<decimal>\d+))?/);
  let number: string;
  if (match && match.groups) {
    const {wholeNumber, decimal} = match.groups;
    number = wholeNumber.padStart(3, '0') + (decimal ? `.${decimal.padEnd(2, '0')}` : '');
  } else {
    console.log(`Error parsing hymn number ${hymn.number}`);
    number = hymn.number.toString();
  }

  if(titleAllCaps.match(/^PSALM \d+$/)) {
    return number + ' - ' + hymn.title;
  }
  else {
    if(hymn.psalm) {
      return number + ' - ' + hymn.title + ' - ' + hymn.psalm;
    }
    else {
      return number + ' - ' + hymn.title;
    }
  }
}

export function generatePathFor(base: string, hymnalName: string, fileName: string) {
  // Cantus Christi hymnals were renamed to include parenthesis (e.g. "Cantus Christi (2002)")
  // Some churches still have the old links (e.g. The King's Congregation)
  if (hymnalName.includes('Cantus Christi') && !hymnalName.includes('(')) {
    hymnalName = hymnalName.replace(/(\d+)/, '($1)')
  }
  return `${base}/${encodeMusicURI(hymnalName)}/${encodeMusicURI(fileName)}`;
}

export function encodeMusicURI(uri: string) {
  return encodeURIComponent(uri)
    .replace(/!/g, '%21');
}

export function getPageFromLocation(location: Location | undefined): Pages | undefined {
  if (!location) {
    return;
  }
  const pagePart = location.pathname.match(/\/(?<page>[^/]+)/)?.groups?.page;
  return pagePart && Object.values(Pages).includes(pagePart as Pages) ? pagePart as Pages : undefined;
}

export function getUrlForPage(page: Pages) {
  return '/' + page;
}

export function getHymnFromSlug({songSlug, hymnals, customMusic}: {
  songSlug: SongSlug,
  hymnals?: HymnalWithHymns[],
  customMusic?: CustomMusic,
}): Hymn | undefined {
  if (!hymnals || !customMusic) {
    return undefined;
  }
  // see SongSlug declaration for slug format examples
  const parts = songSlug.split('/');
  if (parts.length !== 4) {
    return undefined;
  }
  const [hymnalOrChurch, hymnalSlugOrChurchId, songKeyword, userVisibleSongNumber] = parts;
  if (songKeyword !== 'song') {
    console.log(`malformed song slug: ${songSlug}`);
    return undefined;
  }
  const songNumber = getNumericSongNumber(userVisibleSongNumber);
  if (hymnalOrChurch === 'church') {
    const hymns = customMusic[hymnalSlugOrChurchId];
    if (!hymns) {
      console.log(`Unable to retrieve custom music for song slug: ${songSlug}`);
    }
    return hymns.find(hymn => hymn.number === songNumber);
  } else {
    const hymnal = hymnals.find(hymnal => hymnal.slug.toLowerCase() === hymnalSlugOrChurchId.toLowerCase());
    if (!hymnal) {
      return undefined;
    }
    return hymnal.hymns.find(hymn => hymn.number === songNumber);
  }
}

export function generateHymnUrlFromSlug({songSlug, hymnals, customMusic, page, suppressSongIssue}: {
  songSlug: SongSlug,
  hymnals: HymnalWithHymns[],
  customMusic: CustomMusic,
  page?: Pages,
  suppressSongIssue?: boolean,
}): string | undefined {
  let hymn = getHymnFromSlug({songSlug, hymnals, customMusic});
  if (!hymn) {
    return undefined;
  }
  return generateHymnUrl(hymn, page, {suppressSongIssue});
}

export function generateHymnUrl(
  hymn: Hymn,
  page?: Pages,
  {suppressSongIssue}: {suppressSongIssue?: boolean} = {}
): string {
  const hymnalName = hymn.hymnal.replace(/ /g, '_');
  const hymnTitle = hymn.title.replace(/ /g, '_');
  const hymnNumber = hymn.number;
  let psalmName;

  if (hymn.psalm) {
    psalmName = hymn.psalm.replace(/ /g, '_');
  } else {
    psalmName = '';
  }

  const url: string =
    getUrlForPage(page ?? Pages.Library) + '?' +
    'hymnal=' + hymnalName +
    '&title=' + hymnTitle +
    '&number=' + hymnNumber.toString() +
    '&psalm=' + psalmName +
    (hymn.basePath ? '&basePath=' + hymn.basePath : '') +
    (hymn.issue && !suppressSongIssue ? '&issue=' + hymn.issue : '');

  return (url);
}