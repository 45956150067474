import {OnboardingPages} from '../../../common/pages';
import styled from 'styled-components/macro';
import {useEffect, useState} from 'react';

const progressValueMap: Partial<{[key in OnboardingPages]: number}> = {
  [OnboardingPages.SurveyDiscovery]: 1,
  // [OnboardingPages.ChurchChoice]: 2, // maximize room for list of churches
  [OnboardingPages.ShareMusicLeader]: 2,
  [OnboardingPages.SurveyHouseholdSchedule]: 3,
  [OnboardingPages.AllowNotifications]: 4,
  [OnboardingPages.HouseholdStart]: 5,
  [OnboardingPages.HouseholdLastName]: 6,
  [OnboardingPages.HouseholdEmail]: 7,
  [OnboardingPages.ShareFriendsFamily]: 8,
  [OnboardingPages.HouseholdWelcome]: 9,
}

interface Props {
  page: OnboardingPages;
}

export const ProgressBar = ({page}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const value = progressValueMap[page] ?? 0;
  const maxValue = Math.max(...Object.values(progressValueMap));
  const percentage = 100 * value / maxValue;

  useEffect(() => {
    if (value === 0) {
      setVisible(false);
    } else {
      setTimeout(() => setVisible(true), 500);  // avoid obscuring title of previous page (would prefer fade animation)
    }
  }, [value]);

  return (
    <Background style={{opacity: visible ? 1 : 0}}>
      <Fill $percentage={percentage}>
        <Shine/>
      </Fill>
    </Background>
  );
}

const height = '18px';

const Background = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 24px auto auto;
  background-color: var(--color-progress-bar-background);
  height: ${height};
  width: 60%;
  border-radius: calc(${height} / 2);
`;

const Fill = styled.div<{$percentage: number}>`
  display: flex;
  justify-content: center;
  background-color: var(--color-progress-bar-fill);
  width: ${props => props.$percentage}%;
  height: ${height};
  border-radius: calc(${height} / 2);
`;

const Shine = styled.div`
  background-color: var(--color-progress-bar-shine);
  margin-top: calc(${height} / 6);
  height: calc(${height} / 4);
  border-radius: calc(${height} / 8);
  width: max(60%, calc(100% - 20px));
`;

