import React, {useCallback, useEffect, useRef} from 'react';
import {Message, navigateToOnboardingPage, TextInput} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {isValidEmail} from '../../../common/util';
import {localStorageGet, LocalStorageKey, localStorageSet} from '../../data/client_local_storage';
import {alert} from '../../util/confirm';
import * as server_api from '../../../common/server_api';
import {useLocalStorage} from '../../data/use_local_storage';
import {OnboardingPages} from '../../../common/pages';
import {getHouseholdFromServer} from '../../data/use_household';
import {Household} from '../../../common/model';
import {useNavigate} from 'react-router-dom';

export const HouseholdEmail = () => {
  const emailAddressInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    emailAddressInput.current?.focus();
  }, [emailAddressInput]);

  const [householdEmail, setHouseholdEmail] = useLocalStorage(LocalStorageKey.OnboardingHouseholdEmail);
  const onEmailChange = useCallback(() => {
    setHouseholdEmail(emailAddressInput.current?.value);
  }, [setHouseholdEmail]);

  const onContinue = useCallback(async () => {
    const householdEmail = emailAddressInput.current?.value;
    const familyLastName = localStorageGet(LocalStorageKey.OnboardingLastName);
    if (!householdEmail || !isValidEmail(householdEmail)) {
      await alert({confirmation: <span>Please enter a valid email address</span>});
      return false;
    }
    if (!familyLastName) {
      await alert({confirmation: <span>'Please enter your last name'</span>});
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdLastName);
      return;
    }
    let householdToken = await server_api.getHouseholdToken({householdEmail});
    let household: Household | undefined = undefined;
    try {
      if (householdToken) {
        household = await getHouseholdFromServer(householdToken);
      }
    } catch {
      await alert({confirmation: <span>
          Network error.  Please make sure that you are connected to the Internet.
          If this problem persists, contact your internet service provider.
      </span>});
      return;
    }
    if (household) {
      localStorageSet(LocalStorageKey.Household, household);
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdJoin);
    } else {
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdCreate);
    }
  }, [navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdEmail}
      mainContent={(
        <>
          <Message $widthInChars={16}>What is a good email for the family?</Message>
          <TextInput
            type='email'
            enterKeyHint='next'
            defaultValue={householdEmail}
            onChange={onEmailChange}
            ref={emailAddressInput}
          />
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!isValidEmail(householdEmail)}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      containsInputElement={true}
    />
  );
}
