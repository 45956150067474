import React, {useCallback} from 'react';
import styled from 'styled-components/macro';
import {AppStoreUrl, useAppUpdateNeeded} from './billing';
import {HouseholdStatus} from '../../common/model';
import {useHousehold} from '../data/use_household';
import {getOnboardingPath, OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const SignUpButton = ({subscribeButton}: {subscribeButton?: boolean}) => {
  const {household} = useHousehold();
  const householdStatus = household?.status;
  const navigate = useNavigate();
  let message = 'Sign Up';
  let href = getOnboardingPath();
  let isGlobalNavigation = false;
  const appUpdateNeeded = useAppUpdateNeeded();
  if (appUpdateNeeded) {
    message = 'Update';
    href = AppStoreUrl;
    isGlobalNavigation = true;
  } else if (subscribeButton || householdStatus === HouseholdStatus.Unsubscribed) {
    message = 'Subscribe';
    href = getOnboardingPath(OnboardingPages.SubscribeStart)
  }
  const onClick = useCallback((event: any) => {
    event.stopPropagation(); // prevent settings page from handling event as well
    if (isGlobalNavigation) {
      window.location.href = href;
      return;
    }
    navigate(href);
  }, [href, isGlobalNavigation, navigate]);
  return <SignUpButtonStyle onClick={onClick}>{message}</SignUpButtonStyle>
}

export const OptInButton = ({onClick}: {onClick: () => void}) => {
  return <SignUpButtonStyle onClick={onClick}>Opt In</SignUpButtonStyle>
}

const SignUpButtonStyle = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1em;
  color: white;
  background-color: #5200BB;
  padding: 3px 12px;
  border-radius: 30px;
  margin-right: 8px;
  cursor: pointer;
  text-wrap: nowrap;
`;
