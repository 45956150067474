import {useLocalStorage} from './use_local_storage';
import {localStorageGet, LocalStorageKey} from './client_local_storage';
import {useCallback} from 'react';
import {DateString} from '../../common/date_string';
import {DailyHouseholdSingingPlan, SongSlug} from '../../common/model';
import {useHouseholdSingingPlan} from './use_household_singing_plan';

export const singingPlanCompletionDurationMs = 30000;

export function useSingingPlanCompletions(): {
  isSongCompleted: (dateString: DateString, songSlug: SongSlug) => boolean;
  markSongCompleted: (dateString: DateString, songSlug: SongSlug) => void;
} {
  const [completedSongs, setCompletedSongs] = useLocalStorage(LocalStorageKey.HouseholdSingingPlanCompletedSongs);
  const {singingPlan, markSingingPlanComplete} = useHouseholdSingingPlan();

  const isSongCompleted = useCallback((dateString: DateString, songSlug: SongSlug) => {
    return (completedSongs?.[dateString] ?? []).includes(songSlug);
  }, [completedSongs]);

  const markSongCompleted = useCallback((dateString: DateString, songSlug: SongSlug) => {
    if (isSongCompleted(dateString, songSlug)) {
      return;
    }
    setCompletedSongs({...completedSongs, [dateString]: [...(completedSongs?.[dateString] ?? []), songSlug]});

    const dailyPlan = singingPlan?.days.find(({date}) => date === dateString);
    if (dailyPlan && !dailyPlan.completed && allSongsCompleted(dailyPlan)) {
      markSingingPlanComplete(dateString);
    }
  }, [completedSongs, isSongCompleted, setCompletedSongs, markSingingPlanComplete, singingPlan?.days]);

  return {isSongCompleted, markSongCompleted};
}

export function allSongsCompleted(dailyPlan: DailyHouseholdSingingPlan | undefined): boolean {
  if (!dailyPlan) {
    return false;
  }
  const completedSongs = localStorageGet(LocalStorageKey.HouseholdSingingPlanCompletedSongs);
  for (const song of dailyPlan.songs) {
    if (!(completedSongs?.[dailyPlan.date] ?? []).includes(song.slug)) {
      return false;
    }
  }
  return true;
}
