import styled from 'styled-components';

export const Tile = styled.div<{$thinTopPadding?: boolean, $hidden?: boolean}>`
  display: ${props => props.$hidden ? 'none' : 'flex'};
  flex-direction: column;
  gap: 20px;
  margin: 15px 0;
  background-color: var(--color-background);
  padding: ${props => props.$thinTopPadding ? '3px 20px' : '20px'};
  border-radius: 15px;
  box-shadow: 0 0 10px 10px #e0e0e0;
  width: 90%;
`;
