// must not include client-only files - only common files
import {
  AppHashes,
  Church, ChurchAdminCredentials, ChurchTokens, DailyHouseholdSingingPlan,
  Favorite,
  Household, HouseholdSurveyResponses,
  Hymnal, HymnalManifest, HymnalMetadata, HymnalsManifest,
  Issue, PartialAppConfigValues, SongList,
  SongListDeprecated,
  SongRequest, SongSlug, SongView, WeeklyHouseholdSingingPlan,
} from "./model";
import {DateString, dateStringFromDate, validateDateString} from "./date_string";
import {ensureExists} from "./util";
import {hymnalsDir, metadataFilename, musicManifestFilename, thisWeekDirName} from './paths';
import fetch from 'node-fetch';

const hymnalsManifestURL = `/${hymnalsDir}/${musicManifestFilename}`;

// PUT vs POST (https://stackoverflow.com/questions/630453/what-is-the-difference-between-post-and-put-in-http)
//   PUT = idempotent, create or update (like upsert) -> lean toward PUT in most cases
//   POST - only update, not create

// must be registered before accessing server APIs
export type TokenCallback = () => string | undefined;
export interface TokenCallbacks {
  userCallback: TokenCallback | undefined;
  householdCallback: TokenCallback | undefined;
}
let _tokenCallbacks: TokenCallbacks | undefined;
export function registerUserTokenCallbacks(callbacks: TokenCallbacks | undefined): TokenCallbacks | undefined {
  const previousCallbacks = _tokenCallbacks;
  _tokenCallbacks = callbacks;
  return previousCallbacks;
}

function getCurrentUserToken(): string | undefined {
  return ensureExists(
    _tokenCallbacks?.userCallback,
    'call registerUserTokenCallbacks before using server_api'
  )();
}

function getCurrentHouseholdToken(): string | undefined {
  return ensureExists(
    _tokenCallbacks?.householdCallback,
    'call registerUserTokenCallbacks before using server_api'
  )();
}

// for calling from the server
export type FetchOverride = (url: string, init?: RequestInit) => Promise<Response>;
let _fetchOverride: FetchOverride | undefined;
export function setFetchOverride(fetchOverride: FetchOverride) {
  _fetchOverride = fetchOverride;
}

export async function upsertUser(credentials: string): Promise<{id: string, token: string, name: string, email: string}> {
  return (_fetchOverride ?? fetch)('/api/users', {
    method: 'POST',
    headers: {
      'Authorization': credentials,
      'Content-Type': 'application/json'
    },
  })
    .then(response => response.json())
}

function makeNetworkErrorMessage(url: string, message: string, init?: RequestInit, response?: Response) {
  const responseClause = response ? ` (${response.status} - ${response.statusText})` : '';
  return `Network error for request "${url}"${responseClause}: ${message}`;
}

async function performFetch(url: string, init?: RequestInit): Promise<Response> {
  let response: Response;
  try {
    // @ts-ignore
    response = await (_fetchOverride ?? fetch)(url, init);
  } catch (e: any) {
    console.log(makeNetworkErrorMessage(url, e.message + '\n' + e.stack, init));
    throw e;
  }
  if (!response.ok) {
    const message = await response.text() + '\n' + (new Error()).stack;
    console.log(`Stack:\n` + (new Error()).stack);
    throw new Error(makeNetworkErrorMessage(url, message, init, response));
  }
  return response;
}

// TODO(hewitt): deprecated - delete after 8/1/2024, only retained to migrate user church to household
export async function getUserChurch(): Promise<Church | undefined> {
  const response = await performFetch('/api/users/self/church', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const church = await response.json() as Church;
  return !church.id ? undefined : church;
}

export async function getHouseholdChurch(householdToken: string): Promise<Church | undefined> {
  const response = await performFetch(`/api/households/${encodeURIComponent(householdToken)}`, {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const household = await response.json() as Household;
  if (household?.churchId === undefined) {
    return undefined;
  }
  return await getChurch(household.churchId);
}

export async function setHouseholdChurch(householdToken: string, church: Pick<Church, 'id'> | undefined) {
  // undefined is dropped by JSON.stringify, so we pass null across the network to unselect church
  await performFetch(`/api/households/${encodeURIComponent(householdToken)}`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({churchId: church?.id ?? null}),
  })
}

export async function setHouseholdAdmin(householdToken: string, {isAdminForChurchId}: {isAdminForChurchId: number}) {
  await performFetch(`/api/households/${encodeURIComponent(householdToken)}`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({isAdminForChurchId}),
  })
}

export async function generateAllHouseholdSingingPlans({forDate, forHouseholdToken}: {
  forDate: DateString,
  forHouseholdToken?: string,
}) {
  let url = `/api/households/generate-all-singing-plans?date=${forDate}`;
  if (forDate && forHouseholdToken) {
    url += `&householdToken=${forHouseholdToken}`;
  }
  const result = await performFetch(url, {
    method: 'PUT',
    headers: _makeHeaders(),
  });
  return await result.text();
}

export async function upsertChurch(church: Church): Promise<{id: number}> {
  const response = await performFetch('/api/churches', {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({church}),
  });
  const {id} = (await response.json()) as {id: string};
  return {id: Number(id)};
}

// TODO(hewitt): accept a search string so we don't return all 7K churches
// NOTE: Does not include church hymnals -> should only return bare bones, then fill in by calling getChurch
export async function getChurches(): Promise<Church[]> {
  const response = await performFetch('/api/churches', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const churches = await response.json() as Church[];
  return churches;
}

export async function getChurch(id: number): Promise<Church | undefined> {
  const response = await performFetch(`/api/churches/${id}`, {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const church = await response.json() as Church | null;
  return church ?? undefined;
}

export async function deleteChurch(church: Church, {purge}: {purge?: boolean} = {}): Promise<void> {
  await performFetch('/api/churches', {
    method: 'DELETE',
    headers: _makeHeaders(),
    body: JSON.stringify({church, purge}),
  });
}

// TODO(hewitt): Obsolete API for setting church admins from Coda information
//               Delete this API in favor of admin links, which use church token instead of id
export async function setChurchAdmins(churchId: number, adminEmailAddresses: string[]) {
  await performFetch(`/api/churches/${churchId}/admins`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({adminEmailAddresses: adminEmailAddresses}),
  });
}

export async function getChurchTokens(id: number): Promise<ChurchTokens | undefined> {
  const response = await performFetch(`/api/churches/${id}/tokens`, {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const tokens = await response.json() as ChurchTokens | null;
  return tokens ?? undefined;
}

export function churchFromChurchString(churchString: string): Pick<Church, 'name' | 'location'> {
  const regexChurch = /(?<name>.+) \((?<location>[^(]+)\)$/mg;
  const match = regexChurch.exec(churchString);
  if (!match || !match.groups) {
    throw new Error(`Malformed church name ${churchString}`);
  }
  return {
    name: match.groups.name,
    location: match.groups.location,
  };
}

export function stringFromChurch(church: Pick<Church, 'name' |'location'>): string {
  return `${church.name} (${church.location})`;
}

export async function getIsInternalUser(): Promise<boolean> {
  if (!getCurrentUserToken()) {
    return false;
  }
  const response = await performFetch('/api/users/self/internal', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  const isInternal = await response.json() as boolean;
  return isInternal;
}


// TODO(hewitt): pass household token, etc
// export async function uploadChurchCustomMusicFile(churchId: number, filename: string): Promise<Response> {
//   const response = await performFetch(`/api/churches/${churchId}/custom-music/${filename}`, {
//     method: 'PUT',
//     headers: _makeHeaders(),
//   });
//   return response;
// }


// TODO(hewitt): This API is vulnerable!  Pass user/household token eventually to make this API secure!
export async function uploadChurchCustomMusicFile({churchId, songName, psalmNumber, file}: {
  churchId: number,
  songName: string,
  psalmNumber?: number,
  file: File,
}) {
  const formData = new FormData();
  formData.append('songName', songName);
  if (psalmNumber) {
    formData.append('psalmNumber', psalmNumber.toString());
  }
  formData.append('file', file);

  // TODO(hewitt): Was unable to push form data through standard `fetch` API...
  // await performFetch('/api/upload-song', {
  //   method: 'POST',
  //   headers: _makeHeaders(),
  //   body: formData,
  // });

  const url =`/api/churches/${churchId}/custom-music`;
  const request = new XMLHttpRequest();
  request.open("POST", url, true);
  request.setRequestHeader('Authorization', `Bearer ${getCurrentHouseholdToken()}`);
  // TODO(hewitt): Set headers of XMLHttpRequest (wasn't working, but not currently necessary)
  // for (const [key, value] of Object.entries(_makeHeaders())) {
  //   request.setRequestHeader(key, value);
  // }
  await new Promise((resolve, reject) => {
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          const message = 'upload successful';
          console.log(message);
          resolve(message);
        } else {
          const message = request.responseText;
          console.log(`ERROR: Song upload failed: ${message}`);
          reject({message});
        }
      }
    };
    request.send(formData);
  })
}

export async function setSongTempoForOrganization({organizationId, songSlug, songTempo}: {
  organizationId: number;
  songSlug: SongSlug;
  songTempo: number;
}): Promise<void> {
  const url =`/api/churches/${organizationId}/set-song-tempo`;
  await performFetch(url, {
    method: 'PUT',
    headers: _makeHeaders({isHousehold: true}),
    body: JSON.stringify({songSlug, songTempo: songTempo}),
  });
}

export async function getIsSysAdmin(): Promise<boolean> {
  if (!getCurrentUserToken()) {
    return false;
  }
  const response = await performFetch('/api/users/self/sys-admin', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return await response.json();
}

// TODO(hewitt): This is the correct way to identify a church admin, but is not useful without household membership
export async function isChurchAdmin(): Promise<boolean> {
  if (!getCurrentUserToken()) {
    return false;
  }
  const response = await performFetch('/api/users/self/is-church-admin', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return await response.json();
}

export async function grantChurchAdmin(credentials: ChurchAdminCredentials): Promise<void> {
  await performFetch(`/api/church-admin`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify(credentials),
  });
}

export async function revokeChurchAdmin(churchId: number, email: string): Promise<void> {
  await performFetch('/api/church-admin', {
    method: 'DELETE',
    headers: _makeHeaders(),
    body: JSON.stringify({churchId, email})
  });
}

export async function reportSongViews(songViews: SongView[]): Promise<void> {
  await performFetch('/api/song-views', {
    method: 'POST',
    headers: _makeHeaders({allowAnonymousUser: true}),
    body: JSON.stringify({songViews: songViews}),
  });
}

export async function addSongRequest(songRequest: Omit<SongRequest, 'email'>): Promise<void> {
  await performFetch('/api/song-requests', {
    method: 'POST',
    headers: _makeHeaders(),
    body: JSON.stringify({songRequest}),
  });
}

export async function addIssue(issue: Issue): Promise<void> {
  await performFetch('/api/issues', {
    method: 'POST',
    headers: _makeHeaders({allowAnonymousUser: true}),
    body: JSON.stringify({issue}),
  });
}

export async function addFavorite(favorite: Favorite): Promise<void> {
  await performFetch('/api/users/self/favorites', {
    method: 'POST',
    headers: _makeHeaders(),
    body: JSON.stringify(favorite),
  });
}

export async function getFavorites(): Promise<Favorite[]> {
  const response = await performFetch('/api/users/self/favorites', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return await response.json();
}

export async function removeFavorite(favorite: Favorite): Promise<void> {
  await performFetch('/api/users/self/favorites', {
    method: 'DELETE',
    headers: _makeHeaders(),
    body: JSON.stringify(favorite),
  });
}

export async function getAppVersion(): Promise<string> {
  const response = await performFetch('/api/version', {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  });
  return await response.json();
}

export async function getAppHashes(): Promise<AppHashes> {
  const response = await performFetch('/api/app-hashes', {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  });
  return await response.json();
}

export async function upsertSongList(songList: SongListDeprecated): Promise<void> {
  await performFetch('/api/song-lists', {
    method: 'POST',
    headers: _makeHeaders(),
    body: JSON.stringify({songList}),
  })
}

export async function deleteSongList(date: DateString): Promise<void> {
  validateDateString(date);
  await performFetch('/api/song-lists', {
    method: 'DELETE',
    headers: _makeHeaders(),
    body: JSON.stringify({date}),
  });
}

export async function getSongLists(
  church: Church,
  {forDate, from, to}: {forDate?: DateString; from?: DateString; to?: DateString} = {}
): Promise<SongList[]> {
  let url = `/api/song-lists?churchId=${church.id}`;
  if (from) {
    url += `&from=${from}`;
  }
  if (to) {
    url += `&to=${to}`;
  }
  if (forDate) {
    url += `&forDate=${forDate}`;
  }
  const response = await performFetch(url, {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return await response.json();
}

export async function clearHousehold({token}: {token: string}) {
  await performFetch(`/api/billing/clear-household`, {
    method: 'POST',
    headers: _makeHeaders(),
    body: JSON.stringify({token}),
  });
}

function _makeHeaders({allowAnonymousUser, isHousehold}: {allowAnonymousUser?: boolean, isHousehold?: boolean} = {}):
  {Authorization: string; 'Content-Type': string} |
  {'Content-Type': string}
{
  try {
    return {
      'Authorization': `Bearer ${
        isHousehold ? getCurrentHouseholdToken() : getCurrentUserToken()
      }`,
      'Content-Type': 'application/json'
    };
  } catch (error) {
    if (!allowAnonymousUser) {
      throw error;
    }
    return {
      'Content-Type': 'application/json'
    }
  }
}

export async function getThisWeekCodaDocCreatedAt(): Promise<string | undefined> {
  const response = await performFetch('/api/this-week-coda-doc-date', {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return await response.json();
}

export async function setThisWeekCodaDocCreatedAt(date: string): Promise<void> {
  await performFetch('/api/this-week-coda-doc-date', {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({date}),
  });
}

export async function setChurchHymnals(churchId: number, hymnalIds: number[]) {
  await performFetch(`/api/churches/${churchId}/hymnals`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({hymnalIds}),
  });
}

export async function upsertHymnals(hymnals: Hymnal[]): Promise<void> {
  await performFetch('/api/hymnals', {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({hymnals}),
  });
}

export async function getHousehold({householdToken}: {householdToken: string}): Promise<Household | undefined> {
  const url = `/api/households/${encodeURIComponent(householdToken)}`;
  const response = await performFetch(url, {method: 'GET'});
  return await response.json() ?? undefined;
}

export async function getAllHouseholdTokens(): Promise<string[]> {
  const url = '/api/households/all-tokens';
  const response = await performFetch(url, {method: 'GET', headers: _makeHeaders()});
  return await response.json();
}

export async function setUserHousehold({householdToken}: {householdToken: string}) {
  await performFetch(`/api/users/self/household`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({householdToken}),
  })
}

export async function optHouseholdIntoChurchSubscription({householdToken, churchToken}: {
  householdToken: string;
  churchToken: string;
}){
  await performFetch(`/api/households/${householdToken}/opt-into-church-subscription`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({churchToken}),
  })
}

export async function recordHouseholdSurveyResponses({householdToken, responses}: {
  householdToken: string;
  responses: HouseholdSurveyResponses;
}){
  await performFetch(`/api/households/${householdToken}/survey-responses`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({responses}),
  });
}

// TODO(hewitt): this call is insecure - see routes.ts for detail - we should send a link via email to join household
export async function getHouseholdToken({householdEmail}: {householdEmail: string}): Promise<string | undefined> {
  const response = await performFetch(
    `/api/billing/get-household-token?householdEmail=${encodeURIComponent(householdEmail)}`
  );
  const token = await response.json() as string;
  return token ?? undefined;
}

export async function createHousehold({familyLastName, householdEmail}: {
  familyLastName: string, householdEmail: string
}): Promise<Household> {
  const response = await performFetch('/api/billing/create-household-new', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      email: householdEmail,
      familyLastName,
      householdEmail,
    }),
  });
  const household = await response.json() as Household;
  return household;
}

export async function getHouseholdSingingPlan(
  {householdToken}: {householdToken: string}
): Promise<WeeklyHouseholdSingingPlan | undefined> {
  const date = dateStringFromDate(new Date())
  const response = await performFetch(`/api/households/${householdToken}/singing-plan?date=${date}`, {
    method: 'GET',
    headers: _makeHeaders(),
  });
  return (await response.json()) ?? undefined;
}

export async function markSingingPlanCompleted({householdToken, date}: {householdToken: string; date: DateString}) {
  await performFetch(`/api/households/${householdToken}/singing-plan-complete?date=${date}`, {
    method: 'PUT',
    headers: _makeHeaders(),
  });
}

export async function upsertDailyHouseholdSingingPlan(
  householdToken: string,
  plan: Omit<DailyHouseholdSingingPlan, 'completed'>,
): Promise<void> {
  await performFetch(`/api/households/${householdToken}/singing-plan`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(plan),
  });
}

// do not call directly - use isAppConfigEnabled() app_config (client) or database.getAppConfig (server) instead
export async function getAppConfig(): Promise<PartialAppConfigValues> {
  const response = await performFetch('/api/app-config', {method: 'GET'});
  return await response.json();
}

export async function upsertHousehold({householdToken, household}: {
  householdToken: string;
  household: Household;
}) {
  await performFetch(`/api/households/${encodeURIComponent(householdToken)}`, {
    method: 'PUT',
    headers: _makeHeaders(),
    body: JSON.stringify({household}),
  })
}

export async function postGooglePlayPayment({householdToken, paymentResponse, subscriptionExpirationTimestamp}: {
  householdToken: string,
  paymentResponse: PaymentResponse,
  subscriptionExpirationTimestamp: number
}) {
  await performFetch(`/api/billing/google-play/payment/${householdToken}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({paymentResponse, subscriptionExpirationTimestamp}),
  });
}

export async function updateHouseholdWithStripeSubscription({sessionId, householdToken}: {
  sessionId: string;
  householdToken: string;
}) {
  await performFetch('/api/stripe/update-household', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({sessionId, householdToken}),
  });
}

export async function getWeeklySongListsDeprecated(church: Church): Promise<SongListDeprecated[]> {
  const churchManifest = `/${thisWeekDirName}/${stringFromChurch(church)}.json`;
  const response = await performFetch(churchManifest);
  const songsForWeek = await response.json() as SongListDeprecated[];
  return songsForWeek;
}

export async function getHymnalsManifest(): Promise<HymnalsManifest> {
  const response = await performFetch(hymnalsManifestURL);
  const manifest = await response.json() as HymnalsManifest;
  return manifest;
}

// TODO(hewitt): Really should use hymnal slug here (rename hymnal directories to match slugs)
export async function getHymnalMetadata(hymnalName: string): Promise<HymnalMetadata> {
  const response = await performFetch(`/${hymnalsDir}/${hymnalName}/${metadataFilename}`);
  const metadata = await response.json() as HymnalMetadata;
  return metadata;
}

// TODO(hewitt): Really should use hymnal slug here (rename hymnal directories to match slugs)
export async function getHymnalManifest(hymnalName: string): Promise<HymnalManifest> {
  const hymnalManifestUrl = `/${hymnalsDir}/${hymnalName}/${musicManifestFilename}`;
  const response = await performFetch(hymnalManifestUrl);
  const metadata = await response.json() as HymnalManifest;
  return metadata;
}
