import {Hymnal, HymnalManifest} from '../../common/model';
import {Hymn} from '../sequencer';
import {useEffect, useState} from 'react';
import {favorites} from './favorites';
import {useHymnalsManifest} from './use_hymnals_manifest';
import * as server_api from '../../common/server_api';

export interface HymnalWithHymns extends Hymnal {
  hymns: Hymn[];
  message?: JSX.Element;
}

export function useHymnals(): HymnalWithHymns[] | undefined {
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const hymnalsManifest = useHymnalsManifest();

  useEffect(() => {
    if (!hymnalsManifest) {
      return;
    }
    (async () => {
      try {
        const hymnalNames = Object.keys(hymnalsManifest);
        const hymnalManifests: HymnalManifest[] = await Promise.all(
          hymnalNames.map(name => server_api.getHymnalManifest(name))
        );
        const populatedHymnals: HymnalWithHymns[] = hymnalManifests.map((hymnalManifest, index) => {
          const hasSuffix = (number: string) => Boolean(number.match(/\d+\.\d+/));
          const songNumbers = countSongNumbers(hymnalManifest);
          const [name, entry] = Object.entries(hymnalsManifest)[index];
          return {
            name,
            ...entry,
            hymns: getHymnsFromHymnalManifest(name, hymnalManifest,
              // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
              // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
              {filter: (number: string) => hasSuffix(number) || songNumbers[number] <= 2})
          };
        });
        for (const hymnal of populatedHymnals) {
          favorites.registerHymnal(hymnal);
        }
        setHymnals(populatedHymnals);
      } catch (error: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to fetch hymnals: ${error.message}`);
      }
    })();
  }, [hymnalsManifest]);

  return hymnals;
}

function countSongNumbers(hymnalManifest: HymnalManifest) {
  return Object.keys(hymnalManifest)
    .map(key => key.match(/^\d+/)?.[0])
    .filter(key => key !== undefined)
    .reduce((prev, cur) => {
      // @ts-ignore (TS not yet smart enough to apply the filter)
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {} as {[number: string]: number});
}

export function getHymnsFromHymnalManifest(
  hymnalName: string, hymnalManifest: HymnalManifest, {filter, basePath}: {
    filter?: (number: string) => boolean;
    basePath?: string;
  } = {}
): Hymn[] {
  return Object.entries(hymnalManifest)
    // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
    // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
    .filter(([number]) => !filter || filter(number))
    .map(([number, data]) => ({...data, number: Number(number), hymnal: hymnalName, ...(basePath && {basePath})}))
    .sort((lhs, rhs) => lhs.number - rhs.number)
}
