import React, {useCallback, useState} from 'react';
import {ButtonContent, Message, navigateToOnboardingPage, setOnboardingHouseholdSurveyResponse} from './common';
import {Frame} from './frame';
import {Button, LeftAlignedButton} from './button';
import {HouseholdSchedule} from '../../../common/model';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../../common/pages';
import {synchronizeHouseholdWithServer, useHousehold} from '../../data/use_household';
import {isInsideAppleAppStoreApp} from '../../util/billing';
import * as server_api from '../../../common/server_api';
import {useNavigate} from 'react-router-dom';

interface Props {
  onBack?(): void;
}

interface Option {
  prompt: string;
  householdSchedule: HouseholdSchedule;
}

export const SurveyHouseholdSchedule = ({onBack}: Props) => {
  const {household} = useHousehold();
  const {schedule} = useLocalStorage(LocalStorageKey.OnboardingHouseholdSurveyResponses)[0];
  const [householdSchedule, setHouseholdSchedule] = useState<HouseholdSchedule[]>(
    () => household?.surveyResponses?.schedule ?? []);
  const selectedOptions = schedule ?? householdSchedule;
  const navigate = useNavigate();

  const onBackWrapper = useCallback(() => {
    const householdToken = household?.token;
    if (!onBack || !householdToken) {
      console.log(`ERROR: expect onBack & household token`);
      return;
    }
    if (JSON.stringify(household?.surveyResponses?.schedule) !== JSON.stringify(householdSchedule)) {
      void (async () => {
        await server_api.recordHouseholdSurveyResponses({
          householdToken,
          responses: {...household.surveyResponses, schedule: householdSchedule},
        });
        await synchronizeHouseholdWithServer({force: true}); // initializes household.surveyResponses
      })();
    }
    onBack();
  }, [householdSchedule, household?.surveyResponses, household?.token, onBack]);

  const onContinue = useCallback(() => {
    if (onBack && household?.token) {
      return onBackWrapper();
    }
    if (isInsideAppleAppStoreApp()) {
      navigateToOnboardingPage(navigate, OnboardingPages.AllowNotifications);
    } else {
      navigateToOnboardingPage(navigate, household ? OnboardingPages.ShareFriendsFamily : OnboardingPages.HouseholdStart);
    }
  }, [household, onBack, onBackWrapper, navigate]);

  const options: Option[] = [
    {prompt: 'First thing in the morning', householdSchedule: HouseholdSchedule.Morning},
    {prompt: 'During our lunch time', householdSchedule: HouseholdSchedule.Lunch},
    {prompt: 'Around the dinner table', householdSchedule: HouseholdSchedule.Dinner},
    {prompt: 'Just before bedtime', householdSchedule: HouseholdSchedule.Bedtime},
  ];

  const onChooseOption = useCallback((householdSchedule: HouseholdSchedule) => {
    return async () => {
      const isSelected = selectedOptions && selectedOptions.includes(householdSchedule);
      const newOptions = isSelected
        ? selectedOptions.filter(option => option !== householdSchedule)
        : [...(selectedOptions ?? []), householdSchedule];
      if (onBack && household?.token) {
        setHouseholdSchedule(newOptions);
      } else {
        setOnboardingHouseholdSurveyResponse({schedule: newOptions});
      }
    }
  }, [selectedOptions, onBack, household?.token]);

  // force customer to choose an option during onboarding, but not on settings page
  const disabled = (!selectedOptions || selectedOptions.length === 0) && !onBack;

  return (
    <Frame
      onboardingPage={OnboardingPages.SurveyHouseholdSchedule}
      mainContent={(
        <>
          <Message $widthInChars={20}>When will you sing as a household?<br/>(select all that apply)</Message>
          <ButtonContent>
            {
              options.map((option) => (
                <LeftAlignedButton
                  key={option.householdSchedule}
                  onClick={onChooseOption(option.householdSchedule)}
                  notPrimary={true}
                  selected={selectedOptions?.includes(option.householdSchedule)}
                >
                  <OptionWrapper>
                    {option.prompt}
                  </OptionWrapper>
                </LeftAlignedButton>
              ))
            }
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={disabled}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      isSmallBackButton={Boolean(onBack)}
      onBack={onBack && onBackWrapper}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
